// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app'
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export class Firebase {
  private static instance: Firebase

  public readonly analytics: Analytics | undefined

  private constructor() {
    if (process.env.GOOGLE_ANALYTICS_ENABLE == 'true') {
      if (!process.env.FIREBASE_API_KEY) {
        throw new Error('FIREBASE_API_KEY is missing')
      }
      //   if (!process.env.FIREBASE_AUTH_DOMAIN) {
      //     throw new Error('FIREBASE_AUTH_DOMAIN is missing')
      //   }
      if (!process.env.FIREBASE_PROJECT_ID) {
        throw new Error('FIREBASE_PROJECT_ID is missing')
      }
      //   if (!process.env.FIREBASE_STORAGE_BUCKET) {
      //     throw new Error('FIREBASE_STORAGE_BUCKET is missing')
      //   }
      //   if (!process.env.FIREBASE_MESSAGING_SENDER_ID) {
      //     throw new Error('FIREBASE_MESSAGING_SENDER_ID is missing')
      //   }
      if (!process.env.FIREBASE_APP_ID) {
        throw new Error('FIREBASE_APP_ID is missing')
      }
      if (!process.env.FIREBASE_MEASURMENT_ID) {
        throw new Error('FIREBASE_MEASURMENT_ID is missing')
      }

      const firebaseConfig = {
        apiKey: process.env.FIREBASE_API_KEY,
        // authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        projectId: process.env.FIREBASE_PROJECT_ID,
        // storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
        // messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.FIREBASE_APP_ID,
        measurementId: process.env.FIREBASE_MEASURMENT_ID,
      }

      // Initialize Firebase
      const app = initializeApp(firebaseConfig)

      this.analytics = getAnalytics(app)
    }
  }

  public static getInstance(): Firebase {
    if (!Firebase.instance) {
      Firebase.instance = new Firebase()
    }
    return Firebase.instance
  }

  public setCurrentScreen(screen: string | undefined) {
    if (this.analytics) {
      logEvent(this.analytics, 'screen_view', {
        firebase_screen: screen,
        firebase_screen_class: screen,
      })
    }
  }
}
