import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 36 32" width={36} height={32} fill="none" {...props}>
    <Path
      // eslint-disable-next-line max-len
      d="M17.7 18.5c-.433 0-.792-.142-1.075-.425-.283-.283-.425-.642-.425-1.075V7.55c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425.433 0 .792.142 1.075.425.283.283.425.642.425 1.075V17c0 .433-.142.792-.425 1.075-.283.283-.642.425-1.075.425Zm-.1 7.4c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05s.15-.75.45-1.05c.3-.3.65-.45 1.05-.45s.75.15 1.05.45c.3.3.45.65.45 1.05s-.15.75-.45 1.05c-.3.3-.65.45-1.05.45Zm8.2 4.9c-.5.267-.95.258-1.35-.025-.4-.283-.6-.725-.6-1.325 0-.267.092-.542.275-.825.183-.283.408-.492.675-.625 2.267-1.1 4.067-2.75 5.4-4.95 1.333-2.2 2-4.6 2-7.2 0-1.8-.3-3.475-.9-5.025-.6-1.55-1.583-2.942-2.95-4.175l-1.5-1.35v4.35c0 .433-.142.792-.425 1.075-.283.283-.642.425-1.075.425-.433 0-.792-.142-1.075-.425-.283-.283-.425-.642-.425-1.075V1.5c0-.433.142-.792.425-1.075C24.558.142 24.917 0 25.35 0h8.15c.433 0 .792.142 1.075.425.283.283.425.642.425 1.075 0 .433-.142.792-.425 1.075-.283.283-.642.425-1.075.425h-4.7l.75.7c2.2 1.767 3.692 3.733 4.475 5.9.783 2.167 1.175 4.25 1.175 6.25 0 3.233-.858 6.192-2.575 8.875S28.633 29.433 25.8 30.8ZM1.7 32c-.433 0-.792-.142-1.075-.425C.342 31.292.2 30.933.2 30.5c0-.433.142-.792.425-1.075C.908 29.142 1.267 29 1.7 29h4.65l-.7-.7c-2.2-1.733-3.692-3.692-4.475-5.875C.392 20.242 0 18.167 0 16.2c0-3.267.858-6.233 2.575-8.9C4.292 4.633 6.583 2.617 9.45 1.25c.5-.233.942-.225 1.325.025s.575.675.575 1.275c0 .267-.092.542-.275.825A1.721 1.721 0 0 1 10.4 4C8.133 5.133 6.333 6.8 5 9c-1.333 2.2-2 4.6-2 7.2 0 1.733.292 3.375.875 4.925.583 1.55 1.575 2.958 2.975 4.225l1.5 1.35v-4.35c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425.433 0 .792.142 1.075.425.283.283.425.642.425 1.075v8.15c0 .433-.142.792-.425 1.075-.283.283-.642.425-1.075.425H1.7Z"
      fill="#000"
    />
  </Svg>
)

export default SvgComponent
