import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 49 43" width={49} height={43} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M26.095 1C14.004 1 4.19 9.533 4.19 20.048c0 2.933.789 5.676 2.125 8.171L2 41l14.698-3.752a24.931 24.931 0 0 0 9.397 1.847C38.187 39.095 48 30.562 48 20.048 48 9.533 38.187 1 26.095 1Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2}
    />
  </Svg>
)

export default SvgComponent
