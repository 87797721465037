import React from 'react'
import moment from 'moment'
import { Dimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import TimerFilled from '../../../assets/images/components/timer-filled.component'

import TrophyIcon from '../../../assets/images/components/trophy.component'

import { Post, PostSolvingStatus } from '../../types'

import { MainStackProps } from '../../navigators/MainStack'

import { UserBadge } from '../UserBadge'
import { PostPreview } from '../PostPreview'
import { PostActionBar } from '../PostActionBar'

import {
  Container,
  LabelCaption,
  LabelDate,
  PreviewContainer,
  UserContainer,
  UserLabel,
  ActionContainer,
  HeaderContainer,
  BottomContainer,
  TrophyContainer,
  UserInfosContainer,
  DescriptionContainer,
  DateContainer,
  LabelTimer,
  TimerContainer,
} from './PostItem.styled'

interface Props {
  item: Post
  sharedId: string
}

function getFormattedDate(date: Date): string {
  if (moment().add(-1, 'week').isBefore(date)) {
    return moment(date).fromNow()
  }

  return moment(date).format('ll')
}

export const PostItem = (props: Props): JSX.Element => {
  const {
    item,
    item: { createdDate, author, caption },
    sharedId,
  } = props

  let containerWidth = Dimensions.get('window').width

  if (containerWidth > 500) {
    containerWidth = 500
  }

  const meIsAuthor = false

  const { navigate } = useNavigation<MainStackProps['navigation']>()

  const isSolved = item.state.status == PostSolvingStatus.Solved

  const pushToPost = (): void => {
    navigate('ResolvePostScreen', { sharedId })
  }

  return (
    <Container style={{ width: containerWidth }}>
      <HeaderContainer>
        <UserContainer>
          <UserBadge sharedId={sharedId} user={author} size="extra-small" outlined />

          <UserInfosContainer>
            <UserLabel>{author.username}</UserLabel>
          </UserInfosContainer>
        </UserContainer>

        <TrophyContainer disabled>
          <TrophyIcon width={30} height={30} />
        </TrophyContainer>
      </HeaderContainer>

      <PreviewContainer style={{ width: containerWidth, height: containerWidth }}>
        <PostPreview
          key={item.id}
          meIsAuthor={meIsAuthor}
          containerWidth={containerWidth}
          post={item}
          onPressPost={isSolved ? undefined : () => pushToPost()}
        />

        {item.grid?.timeToSolve && (
          <TimerContainer>
            <LabelTimer
              style={{
                textShadowColor: 'black',
                textShadowOffset: { width: 0.5, height: 0.5 },
                textShadowRadius: -1,
              }}
            >
              {item.grid.timeToSolve}
            </LabelTimer>
            <TimerFilled width={32} height={32} />
          </TimerContainer>
        )}

        <DateContainer>
          <LabelDate
            style={{
              textShadowColor: 'black',
              textShadowOffset: { width: 0.5, height: 0.5 },
              textShadowRadius: -1,
            }}
          >
            {getFormattedDate(createdDate)}
          </LabelDate>
        </DateContainer>

        <ActionContainer>
          <PostActionBar meIsAuthor={meIsAuthor} post={item} />
        </ActionContainer>
      </PreviewContainer>

      <BottomContainer>
        <DescriptionContainer>
          <LabelCaption numberOfLines={2}>{caption}</LabelCaption>
        </DescriptionContainer>
      </BottomContainer>
    </Container>
  )
}
