import styled from 'styled-components/native'

export const Container = styled.View``

export const TouchableOpacity = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ImagePreviewContainer = styled.View`
  width: 100%;
  height: 100%;
`

export const ImagePreview = styled.Image`
  width: 100%;
  height: 100%;
`

export const GridPreview = styled.View`
  position: relative;
`

export const SquarePreviewContainer = styled.View`
  position: absolute;
  padding: 1px;
`

export const SquarePreview = styled.Image`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
`

export const LockContainer = styled.View`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
