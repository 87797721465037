import { URL_POST_SIGN_UP, URL_POST_SIGN_IN } from './urls'
import { rest, RestParams } from './rest'
import { AppStorage, AuthInfos } from '../App.storage'
import { getDefaultLangue } from '../translations'

export async function signUp(
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  password: string,
): Promise<AuthInfos | undefined> {
  try {
    const body = JSON.stringify({
      firstName,
      lastName,
      username: username.replace(/ /g, ''),
      locale: getDefaultLangue(),
      email: email.replace(/ /g, ''),
      password,
    })

    const params: RestParams = {
      url: URL_POST_SIGN_UP,
      method: 'POST',
      body,
    }

    const res = await rest(params)
    if (res.status === 200) {
      const { accessToken, refreshToken } = await res.json()
      AppStorage.getInstance().setAuthInfos({ accessToken, refreshToken })
      return { accessToken, refreshToken }
    }
  } catch (error) {
    console.error(error)
  }
}

export async function signInWithCredentials(email: string, password: string): Promise<AuthInfos | undefined> {
  try {
    const body = JSON.stringify({
      grantType: 'password',
      email: email.replace(/ /g, ''),
      password,
    })

    const params: RestParams = {
      url: URL_POST_SIGN_IN,
      method: 'POST',
      body,
    }

    const res = await rest(params)
    if (res.status === 200) {
      const { accessToken, refreshToken } = await res.json()
      AppStorage.getInstance().setAuthInfos({ accessToken, refreshToken })
      return { accessToken, refreshToken }
    }
  } catch (error) {
    console.error(error)
  }
}

export async function signInWithRefreshToken(): Promise<AuthInfos | undefined> {
  const authInfos = AppStorage.getInstance().authInfos

  try {
    const body = JSON.stringify({
      grantType: 'refresh_token',
      refreshToken: authInfos?.refreshToken,
    })

    const params: RestParams = {
      url: URL_POST_SIGN_IN,
      method: 'POST',
      body,
    }

    const res = await rest(params)
    if (res.status === 200) {
      const { accessToken, refreshToken } = await res.json()
      AppStorage.getInstance().setAuthInfos({ accessToken, refreshToken })
      return { accessToken, refreshToken }
    }
  } catch (error) {
    console.error(error)
  }
}
