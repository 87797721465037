import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 50 45" width={50} height={45} fill="none" {...props}>
    <Path
      // eslint-disable-next-line max-len
      d="M34.333 1H15.667c-2.209 0-4.009 1.817-3.925 4.017.016.441.033.883.058 1.316H3c-1.108 0-2 .892-2 2 0 7.717 2.792 13.084 6.542 16.725 3.691 3.592 8.191 5.4 11.508 6.317 1.95.542 3.283 2.167 3.283 3.8a3.162 3.162 0 0 1-3.158 3.158H17A2.664 2.664 0 0 0 14.333 41 2.664 2.664 0 0 0 17 43.667h16A2.664 2.664 0 0 0 35.667 41 2.664 2.664 0 0 0 33 38.333h-2.175a3.162 3.162 0 0 1-3.158-3.158c0-1.633 1.325-3.267 3.283-3.8 3.325-.917 7.825-2.725 11.517-6.317C46.208 21.417 49 16.05 49 8.333c0-1.108-.892-2-2-2h-8.8c.025-.433.042-.866.058-1.316C38.342 2.817 36.542 1 34.333 1ZM5.075 10.333h7.033c.759 7.509 2.434 12.525 4.325 15.884-2.075-.917-4.233-2.209-6.1-4.025C7.667 19.6 5.5 15.858 5.083 10.333h-.008Zm34.6 11.859c-1.867 1.816-4.025 3.108-6.1 4.025 1.892-3.359 3.567-8.375 4.325-15.884h7.033c-.425 5.525-2.591 9.267-5.25 11.859h-.008Z"
      fill="#000"
      stroke="#fff"
    />
  </Svg>
)

export default SvgComponent
