import React from 'react'
import { useRoute } from '@react-navigation/native'

import { ViewPostScreenProps } from '../../navigators/MainStack'

import { useLocalPost } from '../../hooks/useLocalPost.hook'

import { Post, PostSolvingStatus } from '../../types'

import { Refresh } from '../Refresh'
import { Lock } from '../Lock'

import {
  Container,
  TouchableOpacity,
  GridPreview,
  SquarePreviewContainer,
  LockContainer,
  SquarePreview,
  ImagePreview,
  ImagePreviewContainer,
} from './PostPreview.styled'

interface Props {
  post: Post
  meIsAuthor?: boolean
  onPressPost?: () => void
  containerWidth: number
}

export const PostPreview = (props: Props): JSX.Element => {
  const {
    post,
    containerWidth,
    meIsAuthor,
    post: {
      grid,
      state: { status },
    },
    onPressPost,
  } = props

  const route = useRoute<ViewPostScreenProps['route']>()

  const { localPost, loadLocalPost } = useLocalPost(post, route.params.sharedId)

  const displayImage = !grid || meIsAuthor || status == PostSolvingStatus.Solved
  const displayGrid = grid && !meIsAuthor && status != PostSolvingStatus.Solved

  const paddingX = grid && displayGrid ? 2 : 0
  const paddingY = grid && displayGrid ? 2 : 0

  const containerFrame = {
    width: containerWidth - paddingX,
    height: containerWidth - paddingY,
  }

  return (
    <Container style={{ width: containerWidth, height: containerWidth }}>
      {displayImage && localPost && (
        <Refresh loading={localPost.loadingImage} onRefresh={loadLocalPost} small>
          {localPost.image ? (
            <ImagePreviewContainer style={containerFrame}>
              <ImagePreview
                source={{
                  uri: localPost.image.url,
                }}
              />
            </ImagePreviewContainer>
          ) : undefined}
        </Refresh>
      )}

      {displayGrid && localPost && (
        <Refresh loading={localPost.loadingImage || localPost.loadingGrid} onRefresh={loadLocalPost} small>
          {grid && localPost.grid ? (
            <TouchableOpacity activeOpacity={1} onPress={onPressPost}>
              <GridPreview style={containerFrame}>
                {grid.squares.map((square) => {
                  const width = containerFrame.width / grid.x
                  const height = containerFrame.height / grid.y

                  const coordinate = localPost.grid!.coordinates[square.index]
                  const uri = localPost.grid!.squareImageUrl[`${coordinate.y}-${coordinate.x}`]

                  if (square.reverseIndex === undefined || square.reverseIndex === null) {
                    return (
                      <SquarePreviewContainer
                        key={`${square.index}`}
                        style={{
                          top: coordinate.y * height,
                          left: coordinate.x * width,
                          width,
                          height,
                        }}
                      >
                        <LockContainer>
                          <Lock />
                        </LockContainer>
                      </SquarePreviewContainer>
                    )
                  }

                  const coordinateReverse = localPost.grid!.coordinates[square.reverseIndex]

                  return (
                    <SquarePreviewContainer
                      key={`${square.index}`}
                      style={{
                        top: coordinateReverse.y * height,
                        left: coordinateReverse.x * width,
                        width,
                        height,
                      }}
                    >
                      <SquarePreview
                        source={{
                          uri,
                        }}
                      />
                    </SquarePreviewContainer>
                  )
                })}
              </GridPreview>
            </TouchableOpacity>
          ) : undefined}
        </Refresh>
      )}
    </Container>
  )
}
