import { DeviceEventEmitter, EmitterSubscription } from 'react-native'

export const EVENT_SIGN_OUT = 'EVENT_SIGN_OUT'
export const EVENT_FOLLOWER = 'EVENT_FOLLOWER'
export const EVENT_FOLLOWER_ACTION_BLOCK = 'EVENT_FOLLOWER_ACTION_BLOCK'
export const EVENT_FOLLOWER_ACTION_REPORT = 'EVENT_FOLLOWER_ACTION_REPORT'

export class AppEvent {
  public static dispatchSignOut(): void {
    DeviceEventEmitter.emit(EVENT_SIGN_OUT)
  }

  public static subscribeSignOut(listener: () => void): void {
    DeviceEventEmitter.addListener(EVENT_SIGN_OUT, listener)
  }

  public static removeAllListeners(): void {
    DeviceEventEmitter.removeAllListeners()
  }

  public static dispatchEvent(key: string, params?: object): void {
    DeviceEventEmitter.emit(key, params)
  }

  public static subscribeEvent(key: string, listener: (params?: any) => void): EmitterSubscription {
    return DeviceEventEmitter.addListener(key, listener)
  }
}
