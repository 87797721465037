import { StackScreenProps } from '@react-navigation/stack'

import { ViewPostScreenName, ViewPostScreenType, ViewPostScreenParams } from '../../screens/ViewPost'
import { ResolvePostScreenName, ResolvePostScreenType, ResolvePostScreenParams } from '../../screens/ResolvePost'

export const MainStackName = 'MainStack'

export type MainStackType = typeof MainStackName

export type MainStackParamList = {
  [ViewPostScreenName]: ViewPostScreenParams
  [ResolvePostScreenName]: ResolvePostScreenParams
}

export type ViewPostScreenProps = StackScreenProps<MainStackParamList, ViewPostScreenType>
export type ResolvePostScreenProps = StackScreenProps<MainStackParamList, ResolvePostScreenType>

export type MainStackProps = StackScreenProps<MainStackParamList>
