import { useQuery, ApolloError } from '@apollo/client'

import { SHARED_POST } from '../apollo/queries'

import { Post, QuerySharedPostArgs } from '../types'

interface Data {
  sharedPost?: Post
}

export const useSharedPost = (
  postId: string,
): {
  loading: boolean
  error: ApolloError | undefined
  refetch: (postId: string) => void
  data?: Data
} => {
  const { loading, error, data, refetch } = useQuery<Data, QuerySharedPostArgs>(SHARED_POST, {
    variables: { postId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const refetchData = (postId: string) => {
    refetch({ postId })
  }

  return { loading, error, data, refetch: refetchData }
}
