import React from 'react'
import { createStackNavigator, CardStyleInterpolators, StackNavigationOptions } from '@react-navigation/stack'

import { ResolvePostScreen, ResolvePostScreenName } from '../../screens/ResolvePost'
import { ViewPostScreen, ViewPostScreenName } from '../../screens/ViewPost'

import { HeaderViewPostScreenTitle } from '../../components/HeaderViewPostScreenTitle'

import { HeaderResolvePostScreenTitle } from '../../components/HeaderResolvePostScreenTitle'
import { HeaderResolvePostScreenFollowerProfile } from '../../components/HeaderResolvePostScreenFollowerProfile'

import { MainStackParamList } from './MainStack.params'

export const Stack = createStackNavigator<MainStackParamList>()

export const MainStack = (): JSX.Element => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: 'black',
        headerBackTitleVisible: false,

        headerTitleStyle: {
          fontFamily: 'NotoSans-Bold',
          fontSize: 15,
        },

        headerStyle: {
          shadowColor: 'white',
          borderBottomColor: 'white',
        },
      }}
    >
      <Stack.Group
        screenOptions={{
          cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
        }}
      >
        <Stack.Screen
          name={ViewPostScreenName}
          component={ViewPostScreen}
          options={() => ({
            title: 'Everyday I Shufflin',

            headerTitle: () => <HeaderViewPostScreenTitle />,

            cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
          })}
        />

        <Stack.Screen
          name={ResolvePostScreenName}
          component={ResolvePostScreen}
          options={({ navigation }) => {
            const options: StackNavigationOptions = {
              title: 'Solve',

              headerTitle: () => <HeaderResolvePostScreenTitle />,

              headerRight: () => <HeaderResolvePostScreenFollowerProfile />,

              gestureEnabled: false,
            }

            const routes = navigation.getState().routes || []

            const isFromViewPostScreen = routes[routes.length - 2]?.name == 'ViewPostScreen'

            if (!isFromViewPostScreen) {
              options.headerMode = 'screen'
            }

            return options
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  )
}
