import { useTranslation } from 'react-i18next'

import { Container, A, Icon, Label } from './HeaderViewPostScreenTitle.styled'

export const HeaderViewPostScreenTitle = (): JSX.Element => {
  const { i18n } = useTranslation()

  const path = i18n.language == 'en' ? '' : `/${i18n.language}`
  const href = `https://shufflin.co${path}`

  return (
    <A href={href}>
      <Container>
        <Icon source={require('../../../assets/images/icon.png')} />
        <Label>Everyday I Shufflin</Label>
      </Container>
    </A>
  )
}
