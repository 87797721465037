import { useTranslation } from 'react-i18next'

import Retry from '../../../../assets/images/components/retry.component'

import { Container, Label } from './Error.styled'

interface Props {
  small?: boolean
}

export const Error = (props: Props): JSX.Element => {
  const { small } = props

  const { t } = useTranslation()

  return (
    <Container>
      <Retry width={24} height={24} />
      <Label style={{ fontSize: small ? 12 : 16 }}>{t('components_Refresh_Error_label')}</Label>
    </Container>
  )
}
