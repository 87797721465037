import {
  ApolloClient as Client,
  createHttpLink,
  InMemoryCache,
  from,
  split,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { cloneDeep } from 'lodash'
import { persistCache, AsyncStorageWrapper } from 'apollo3-cache-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { signInWithRefreshToken } from '../utils/auth'
import {
  PaginatedFollower,
  PaginatedMessage,
  PaginatedThread,
  Thread,
  Follower,
  PostComment,
  PaginatedPostComment,
  Post,
  PaginatedPost,
} from '../types'

import { AppEvent } from '../App.event'
import { AppStorage } from '../App.storage'

import { mergePaginated } from './merge'

export type ApolloClient = Client<NormalizedCacheObject>

export async function configureClient(): Promise<ApolloClient> {
  const cache = new InMemoryCache({
    typePolicies: {
      Thread: {
        keyFields: ['id'],
        fields: {
          messages: {
            keyArgs: ['threadId'],
            merge(existing: PaginatedMessage | undefined, incoming: PaginatedMessage, options) {
              let newIncoming = cloneDeep(incoming)

              const { readField } = options

              if (existing && newIncoming.pageInfo.hasPreviousPage) {
                const mergedMessages = newIncoming.edges.reduce((acc, message) => {
                  const existingMessage = existing.edges.find(
                    (m) => readField('id', m.node) === readField('id', message.node),
                  )
                  if (!existingMessage) {
                    return [message, ...acc]
                  }
                  return acc
                }, existing.edges)

                newIncoming = {
                  ...newIncoming,
                  edges: mergedMessages,
                }
              }

              return newIncoming
            },
          },
        },
      },
      Query: {
        fields: {
          threads: {
            keyArgs: false,
            ...mergePaginated<Thread, PaginatedThread>(),
          },
          followers: {
            keyArgs: ['userId'],
            ...mergePaginated<Follower, PaginatedFollower>(),
          },
          following: {
            keyArgs: ['userId'],
            ...mergePaginated<Follower, PaginatedFollower>(),
          },
          feed: {
            keyArgs: ['filters'],
            ...mergePaginated<Post, PaginatedPost>(),
          },
          publicFeed: {
            keyArgs: ['filters'],
            ...mergePaginated<Post, PaginatedPost>(),
          },
          myPosts: {
            keyArgs: false,
            ...mergePaginated<Post, PaginatedPost>(),
          },
          myLikedPosts: {
            keyArgs: false,
            ...mergePaginated<Post, PaginatedPost>(),
          },
          mySolvedPosts: {
            keyArgs: false,
            ...mergePaginated<Post, PaginatedPost>(),
          },
          posts: {
            keyArgs: ['userId'],
            ...mergePaginated<Post, PaginatedPost>(),
          },
          postsLiked: {
            keyArgs: ['userId'],
            ...mergePaginated<Post, PaginatedPost>(),
          },
          postsSolved: {
            keyArgs: ['userId'],
            ...mergePaginated<Post, PaginatedPost>(),
          },
          postComments: {
            keyArgs: ['postId'],
            ...mergePaginated<PostComment, PaginatedPostComment>(),
          },
        },
      },
    },
  })

  await persistCache({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
  })

  if (!process.env.WS_GRAPHQL_ENDPOINT) {
    throw new Error("'WS_GRAPHQL_ENDPOINT' has not been set")
  }

  const httpLink = createHttpLink({
    uri: process.env.API_GRAPHQL_ENDPOINT,
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.WS_GRAPHQL_ENDPOINT,
      lazy: true,
      // reconnect: true,
      connectionParams: () => {
        const authInfos = AppStorage.getInstance().authInfos

        return {
          Authorization: authInfos?.accessToken ? `Bearer ${authInfos?.accessToken}` : '',
        }
      },
    }),
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )

  const withAccessToken = setContext((_, { headers }) => {
    const authInfos = AppStorage.getInstance().authInfos

    return {
      headers: {
        ...headers,
        Authorization: authInfos?.accessToken ? `Bearer ${authInfos?.accessToken}` : '',
      },
    }
  })

  const autoSignInWithRefreshToken = setContext(async (_, previousContext) => {
    if (previousContext.isUnauthorized) {
      const authInfos = await signInWithRefreshToken()

      if (authInfos) {
        return {
          headers: {
            ...previousContext.headers,
            Authorization: `Bearer ${authInfos.accessToken}`,
          },
        }
      } else {
        AppEvent.dispatchSignOut()
      }
    }
  })

  const checkIfUnauthorizedError = onError(({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
      const isUnauthorized = graphQLErrors.reduce((acc, error) => {
        const { message, locations, path } = error
        // eslint-disable-next-line no-console
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        return acc ? acc : message === 'Unauthorized'
      }, false)

      if (isUnauthorized) {
        operation.setContext({
          isUnauthorized: true,
        })
        return forward(operation)
      }
    }
  })

  return new Client({
    cache,
    link: from([withAccessToken, checkIfUnauthorizedError, autoSignInWithRefreshToken, splitLink]),
  })
}
