import { ThunkAction } from 'redux-thunk'

import { AppState } from '../store'

import { User, ProfileImage } from '../../types'

import { downloadImage } from '../../utils/download-image'

import { PROFILE_IMAGE_DOWNLOAD, ProfileImageActionTypes } from './actionTypes'

type Thunk<R> = ThunkAction<R, AppState, null, ProfileImageActionTypes>

export const downloadProfileImage =
  (user: User, sharedId: string): Thunk<void> =>
  async (dispatch, state) => {
    if (!user.profileImageUrl) {
      return
    }

    const paths = user.profileImageUrl.split('/')
    const imageId = paths[paths.length - 1]

    const images = state().profileImage.images
    const image: ProfileImage = images.find((i) => i.id === imageId) || {
      id: imageId,
      sharedId,
      url: user.profileImageUrl,
      loading: 0,
    }

    const isLoadingImage = image.loading == 1
    const isUrlValid = image.url == user.profileImageUrl

    let isImagePathValid = false

    try {
      isImagePathValid = !!image.path
    } catch (error) {}

    const isLoadingRequired = !isLoadingImage && (!isUrlValid || !isImagePathValid)

    if (isLoadingRequired) {
      dispatch({
        type: PROFILE_IMAGE_DOWNLOAD,
        image: {
          ...image,
          path: undefined,
          loading: 1,
        },
      })

      if (!process.env.API_REST_ENDPOINT) {
        throw new Error("'API_REST_ENDPOINT' has not been set")
      }

      const url = `${process.env.API_REST_ENDPOINT}/user-profile-images/shared/${sharedId}`

      try {
        const loadedImage = await downloadImage(url)

        dispatch({
          type: PROFILE_IMAGE_DOWNLOAD,
          image: {
            ...image,
            path: loadedImage,
            loading: 0,
          },
        })
      } catch (error) {
        dispatch({
          type: PROFILE_IMAGE_DOWNLOAD,
          image: {
            ...image,
            path: undefined,
            loading: -1,
          },
        })
      }
    }
  }
