import styled from 'styled-components/native'

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.main};
  justify-content: space-between;
`

export const Label = styled.Text`
  min-height: 30px;
  font-weight: 500;
  text-align: center;
`
