export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export type CommentPostInput = {
  content: Scalars['String'];
  postId: Scalars['ID'];
};

export type CreateOrUpdateDeviceInput = {
  OS: Scalars['String'];
  Version: Scalars['String'];
  deviceId: Scalars['ID'];
  deviceName: Scalars['String'];
  expoPushToken?: InputMaybe<Scalars['String']>;
};

export type DailyPostInput = {
  postId: Scalars['ID'];
};

export type EdgeFollower = {
  __typename?: 'EdgeFollower';
  cursor?: Maybe<Scalars['ID']>;
  node: Follower;
};

export type EdgeMessage = {
  __typename?: 'EdgeMessage';
  cursor?: Maybe<Scalars['ID']>;
  node: Message;
};

export type EdgePost = {
  __typename?: 'EdgePost';
  cursor?: Maybe<Scalars['ID']>;
  node: Post;
};

export type EdgePostComment = {
  __typename?: 'EdgePostComment';
  cursor?: Maybe<Scalars['ID']>;
  node: PostComment;
};

export type EdgeThread = {
  __typename?: 'EdgeThread';
  cursor?: Maybe<Scalars['ID']>;
  node: Thread;
};

export type Follower = {
  __typename?: 'Follower';
  blocking: Scalars['Boolean'];
  followed: Scalars['Boolean'];
  following: Scalars['Boolean'];
  id: Scalars['ID'];
  pended: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  user: User;
};

export type FollowerRank = {
  __typename?: 'FollowerRank';
  attemptToSolve?: Maybe<Scalars['Float']>;
  follower: Follower;
  rank: Scalars['Float'];
  solvedAt?: Maybe<Scalars['DateTime']>;
  timeToSolve?: Maybe<Scalars['Float']>;
};

export enum GrantType {
  Password = 'Password',
  RefreshToken = 'RefreshToken'
}

export type Grid = {
  __typename?: 'Grid';
  squares: Array<Square>;
  timeToSolve?: Maybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type GridInput = {
  squares: Array<SquareInput>;
  timeToSolve?: InputMaybe<Scalars['Float']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type LikePostInput = {
  like: Scalars['Boolean'];
  postId: Scalars['ID'];
};

export type Message = {
  __typename?: 'Message';
  authorId: Scalars['ID'];
  content: Scalars['String'];
  createdDate: Scalars['DateTime'];
  deletedByAuthor: Scalars['Boolean'];
  deletedByRecipient: Scalars['Boolean'];
  id: Scalars['ID'];
  post?: Maybe<Post>;
  read: Scalars['Boolean'];
  recipientId: Scalars['ID'];
  recipientState?: Maybe<PostState>;
  threadId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptRequest: Follower;
  block: Follower;
  cancelRequest: Follower;
  commentPost: PostComment;
  createOrUpdateDevice: Scalars['Boolean'];
  createPostImageDraft: PostImageDraft;
  createUserProfileImageDraft: UserProfileImageDraft;
  deletePost: Scalars['Boolean'];
  findOrCreateThread: Thread;
  follow: Follower;
  likePost: Post;
  markThreadAsRead: Thread;
  reportPost: Scalars['Boolean'];
  reportUser: Scalars['Boolean'];
  sendMessage: Message;
  sendPost: Post;
  setDailyPost: Scalars['Boolean'];
  sharePost: Scalars['String'];
  signIn: AuthPayload;
  signOut: Scalars['Boolean'];
  signUp: AuthPayload;
  solvePost: Post;
  unBlock: Follower;
  unFollow: Follower;
  updateLocale: Scalars['Boolean'];
  updatePostStatus: Post;
  updatePrivacy: Scalars['Boolean'];
  validateUserProfileImage: Scalars['Boolean'];
};


export type MutationAcceptRequestArgs = {
  userId: Scalars['String'];
};


export type MutationBlockArgs = {
  userId: Scalars['String'];
};


export type MutationCancelRequestArgs = {
  userId: Scalars['String'];
};


export type MutationCommentPostArgs = {
  input: CommentPostInput;
};


export type MutationCreateOrUpdateDeviceArgs = {
  input: CreateOrUpdateDeviceInput;
};


export type MutationDeletePostArgs = {
  postId: Scalars['String'];
};


export type MutationFindOrCreateThreadArgs = {
  userId: Scalars['String'];
};


export type MutationFollowArgs = {
  userId: Scalars['String'];
};


export type MutationLikePostArgs = {
  input: LikePostInput;
};


export type MutationMarkThreadAsReadArgs = {
  threadId: Scalars['String'];
};


export type MutationReportPostArgs = {
  input: ReportPostInput;
};


export type MutationReportUserArgs = {
  input: ReportUserInput;
};


export type MutationSendMessageArgs = {
  content: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSendPostArgs = {
  input: PostInput;
};


export type MutationSetDailyPostArgs = {
  input: DailyPostInput;
};


export type MutationSharePostArgs = {
  input: SharePostInput;
};


export type MutationSignInArgs = {
  input: UserSignInInput;
};


export type MutationSignOutArgs = {
  input: UserSignOutInput;
};


export type MutationSignUpArgs = {
  input: UserRegisterInput;
};


export type MutationSolvePostArgs = {
  input: SolvePostInput;
};


export type MutationUnBlockArgs = {
  userId: Scalars['String'];
};


export type MutationUnFollowArgs = {
  userId: Scalars['String'];
};


export type MutationUpdateLocaleArgs = {
  input: UpdateLocaleInput;
};


export type MutationUpdatePostStatusArgs = {
  input: UpdatePostStatusInput;
};


export type MutationUpdatePrivacyArgs = {
  input: UpdatePrivacyInput;
};


export type MutationValidateUserProfileImageArgs = {
  input: ValidateUserProfileImageInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['ID']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['ID']>;
};

export type PaginatedFollower = {
  __typename?: 'PaginatedFollower';
  edges: Array<EdgeFollower>;
  pageInfo: PageInfo;
};

export type PaginatedMessage = {
  __typename?: 'PaginatedMessage';
  edges: Array<EdgeMessage>;
  pageInfo: PageInfo;
};

export type PaginatedPost = {
  __typename?: 'PaginatedPost';
  edges: Array<EdgePost>;
  pageInfo: PageInfo;
};

export type PaginatedPostComment = {
  __typename?: 'PaginatedPostComment';
  edges: Array<EdgePostComment>;
  pageInfo: PageInfo;
};

export type PaginatedThread = {
  __typename?: 'PaginatedThread';
  edges: Array<EdgeThread>;
  pageInfo: PageInfo;
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['ID']>;
  first: Scalars['Float'];
};

export type Post = {
  __typename?: 'Post';
  author: User;
  authorId: Scalars['ID'];
  caption: Scalars['String'];
  createdDate: Scalars['DateTime'];
  grid?: Maybe<Grid>;
  id: Scalars['ID'];
  imageUrl: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  ranking: PostRanking;
  state: PostState;
  statistics: PostStatistics;
};

export type PostComment = {
  __typename?: 'PostComment';
  author: User;
  content: Scalars['String'];
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  postId: Scalars['ID'];
};

export type PostFiltersInput = {
  unsolved?: InputMaybe<Scalars['Boolean']>;
  x?: InputMaybe<Scalars['Float']>;
};

export type PostImageDraft = {
  __typename?: 'PostImageDraft';
  id: Scalars['ID'];
  uploadLink: Scalars['String'];
};

export type PostInput = {
  caption?: InputMaybe<Scalars['String']>;
  grid?: InputMaybe<GridInput>;
  imageId: Scalars['String'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  userIds: Array<Scalars['ID']>;
};

export type PostRanking = {
  __typename?: 'PostRanking';
  attempt?: Maybe<FollowerRank>;
  date?: Maybe<FollowerRank>;
  time?: Maybe<FollowerRank>;
};

export enum PostSolvingStatus {
  Failed = 'FAILED',
  Opened = 'OPENED',
  Pending = 'PENDING',
  Solved = 'SOLVED'
}

export type PostState = {
  __typename?: 'PostState';
  attemptToSolve: Scalars['Float'];
  like: Scalars['Boolean'];
  status: PostSolvingStatus;
  timeToSolve: Scalars['Float'];
};

export type PostStatistics = {
  __typename?: 'PostStatistics';
  countOfComments: Scalars['Float'];
  countOfLikes: Scalars['Float'];
  countOfSolved: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  blockedFollowers: PaginatedFollower;
  dailyPost: Post;
  feed: PaginatedPost;
  follower: Follower;
  followers: PaginatedFollower;
  following: PaginatedFollower;
  me: User;
  myLikedPosts: PaginatedPost;
  myPosts: PaginatedPost;
  mySolvedPosts: PaginatedPost;
  pendingFollowers: PaginatedFollower;
  post: Post;
  postComments: PaginatedPostComment;
  postRanking: Array<FollowerRank>;
  posts: PaginatedPost;
  postsLiked: PaginatedPost;
  postsSolved: PaginatedPost;
  publicFeed: PaginatedPost;
  ranking: Array<FollowerRank>;
  searchFollowers: Array<Follower>;
  sharedPost: Post;
  thread: Thread;
  threads: PaginatedThread;
};


export type QueryBlockedFollowersArgs = {
  pagination: PaginationInput;
};


export type QueryFeedArgs = {
  filters?: InputMaybe<PostFiltersInput>;
  pagination: PaginationInput;
};


export type QueryFollowerArgs = {
  userId: Scalars['String'];
};


export type QueryFollowersArgs = {
  pagination: PaginationInput;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryFollowingArgs = {
  pagination: PaginationInput;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryMyLikedPostsArgs = {
  pagination: PaginationInput;
};


export type QueryMyPostsArgs = {
  pagination: PaginationInput;
};


export type QueryMySolvedPostsArgs = {
  pagination: PaginationInput;
};


export type QueryPendingFollowersArgs = {
  pagination: PaginationInput;
};


export type QueryPostArgs = {
  postId: Scalars['String'];
};


export type QueryPostCommentsArgs = {
  pagination: PaginationInput;
  postId: Scalars['String'];
};


export type QueryPostRankingArgs = {
  postId: Scalars['String'];
  sorting: RankingSorting;
};


export type QueryPostsArgs = {
  pagination: PaginationInput;
  userId: Scalars['String'];
};


export type QueryPostsLikedArgs = {
  pagination: PaginationInput;
  userId: Scalars['String'];
};


export type QueryPostsSolvedArgs = {
  pagination: PaginationInput;
  userId: Scalars['String'];
};


export type QueryPublicFeedArgs = {
  filters?: InputMaybe<PostFiltersInput>;
  pagination: PaginationInput;
};


export type QuerySearchFollowersArgs = {
  queryField: Scalars['String'];
};


export type QuerySharedPostArgs = {
  postId: Scalars['String'];
};


export type QueryThreadArgs = {
  threadId: Scalars['String'];
};


export type QueryThreadsArgs = {
  pagination: PaginationInput;
};

export enum RankingSorting {
  Attempt = 'ATTEMPT',
  Date = 'DATE',
  Time = 'TIME'
}

export type ReportPostInput = {
  description: Scalars['String'];
  postId: Scalars['ID'];
};

export type ReportUserInput = {
  description: Scalars['String'];
  userId: Scalars['ID'];
};

export type SharePostInput = {
  postId: Scalars['ID'];
};

export type SolvePostInput = {
  attemptToSolve: Scalars['Float'];
  postId: Scalars['ID'];
  timeToSolve: Scalars['Float'];
};

export type Square = {
  __typename?: 'Square';
  index: Scalars['Float'];
  reverseIndex?: Maybe<Scalars['Float']>;
};

export type SquareInput = {
  index: Scalars['Float'];
  reverseIndex?: InputMaybe<Scalars['Float']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  messageSent: Thread;
  messageUpdated: Message;
};

export type Thread = {
  __typename?: 'Thread';
  author: User;
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  lastMessage?: Maybe<Message>;
  messages: PaginatedMessage;
  notReadByAuthor: Scalars['Float'];
  notReadByRecipient: Scalars['Float'];
  recipient: User;
  updatedDate: Scalars['DateTime'];
};


export type ThreadMessagesArgs = {
  pagination: PaginationInput;
};

export type UpdateLocaleInput = {
  locale: Scalars['String'];
};

export type UpdatePostStatusInput = {
  postId: Scalars['ID'];
  status: PostSolvingStatus;
};

export type UpdatePrivacyInput = {
  isPublic: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  createdDate: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
  role: UserRole;
  statistics: UserStatistics;
  updatedDate: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UserProfileImageDraft = {
  __typename?: 'UserProfileImageDraft';
  id: Scalars['ID'];
  uploadLink: Scalars['String'];
};

export type UserRegisterInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleUser = 'ROLE_USER'
}

export type UserSignInInput = {
  email?: InputMaybe<Scalars['String']>;
  grantType: GrantType;
  password?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type UserSignOutInput = {
  refreshToken: Scalars['String'];
};

export type UserStatistics = {
  __typename?: 'UserStatistics';
  followersCount: Scalars['Float'];
  followingCount: Scalars['Float'];
  postsCount: Scalars['Float'];
  solvedPostsCount: Scalars['Float'];
};

export type ValidateUserProfileImageInput = {
  userProfileImageId: Scalars['ID'];
};
