import styled from 'styled-components/native'

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CircleTimeConainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CircleTimeLabel = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-SemiBold;
  font-size: 16px;
  color: black;
  height: 20px;
`
