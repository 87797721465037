import styled from 'styled-components/native'

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const TouchableOpacity = styled.TouchableOpacity`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin: 10px 0;
`

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-SemiBold;
  font-size: 16px;
  color: white;
`
