/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react'
import { Animated } from 'react-native'

// import LockIcon from '../../../assets/images/components/lock-closed.component'
import LockIcon from '../../../assets/images/components/lock.component'

import { TouchableOpacity } from './Lock.styled'

interface Props {
  animate?: boolean
}

export const Lock = (props: Props): JSX.Element => {
  const { animate } = props

  const shakeAnimation = useRef(new Animated.Value(0))

  const startShake = () => {
    Animated.sequence([
      Animated.timing(shakeAnimation.current, { toValue: 10, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation.current, { toValue: -10, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation.current, { toValue: 10, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation.current, { toValue: 0, duration: 100, useNativeDriver: true }),
    ]).start()

    setTimeout(() => {
      setAnimated(false)
    }, 400)
  }

  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    if (animate || animated) {
      startShake()
    }
  }, [animate, animated])

  return (
    <TouchableOpacity
      onPress={() => {
        setAnimated(true)
      }}
    >
      <Animated.View style={{ transform: [{ translateX: shakeAnimation.current }] }}>
        <LockIcon width={20} height={20} />
      </Animated.View>
    </TouchableOpacity>
  )
}
