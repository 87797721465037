import { applyMiddleware, combineReducers, compose, createStore, Store, AnyAction } from 'redux'
import thunk from 'redux-thunk'
import { throttle } from 'lodash'

import { Sentry } from '../analytics/sentry'

import { app, defaultState as DefaultAppState } from './app/reducers'
import { postPreview } from './post-preview/reducers'
import { profileImage } from './profile-image/reducers'

import { saveData, loadData } from '../utils/storage'

const KEY_REDUX_STATE = 'REDUX_STATE'

const defaultReducer = {
  app,
  postPreview,
  profileImage,
}

const rootReducer = combineReducers(defaultReducer)

const REDUX_DEVTOOLS = typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const composeEnhancers = REDUX_DEVTOOLS || compose

const saveState = async (state: AppState) => {
  saveData<AppState>(KEY_REDUX_STATE, state)
}

const loadState = async (): Promise<AppState | undefined> => {
  try {
    const state = await loadData<AppState>(KEY_REDUX_STATE)
    if (state) {
      const cleanedState: AppState = {
        ...state,
        app: DefaultAppState,
        postPreview: {
          ...state.postPreview,
          posts: state.postPreview.posts.map((post) => ({ ...post, loadingImage: 0, loadingGrid: 0 })),
        },
        profileImage: {
          ...state.profileImage,
          images: state.profileImage.images.map((image) => ({ ...image, loading: 0 })),
        },
      }

      return cleanedState
    }
  } catch (error) {
    Sentry.Browser.captureException(error)
  }

  return undefined
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const configureStore = async () => {
  const state = (await loadState()) || {}
  const store = createStore(rootReducer, state, composeEnhancers(applyMiddleware(thunk)))
  store.subscribe(throttle(() => saveState(store.getState()), 1000))
  return store
}

export type AppState = ReturnType<typeof rootReducer>
export type ReduxStore = Store<AppState, AnyAction>
