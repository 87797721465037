import styled from 'styled-components/native'

export type Size = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large' | 'tab-bar'

const containerSizes: { [key in Size]: number } = {
  'tab-bar': 28,
  'extra-small': 36,
  small: 50,
  medium: 60,
  large: 100,
  'extra-large': 150,
}

const badgeContainerSizes: { [key in Size]: number } = {
  'tab-bar': 28,
  'extra-small': 36,
  small: 50,
  medium: 60,
  large: 100,
  'extra-large': 150,
}

const labelSizes: { [key in Size]: number } = {
  'tab-bar': 16,
  'extra-small': 16,
  small: 16,
  medium: 16,
  large: 20,
  'extra-large': 22,
}

export const TouchableOpacity = styled.TouchableOpacity<{ size: Size; displayUsername?: boolean }>`
  height: ${({ size, displayUsername }) => containerSizes[size] + (displayUsername ? 25 : 0)}px;
  width: ${({ size, displayUsername }) => containerSizes[size] + (displayUsername ? 50 : 0)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const BadgeContainer = styled.View<{ size: Size; outlined?: boolean }>`
  height: ${({ size }) => badgeContainerSizes[size]}px;
  width: ${({ size }) => badgeContainerSizes[size]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ size }) => badgeContainerSizes[size] / 2}px;
  border: ${({ outlined }) => (outlined ? 1 : 0)}px solid black;
`

export const LoadingContainer = styled.View<{ size: Size }>`
  height: ${({ size }) => badgeContainerSizes[size]}px;
  width: ${({ size }) => badgeContainerSizes[size]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ size }) => badgeContainerSizes[size] / 2}px;
  background-color: white;
`

export const ProfileImage = styled.Image<{ size: Size; outlined?: boolean }>`
  height: ${({ size }) => badgeContainerSizes[size]}px;
  width: ${({ size }) => badgeContainerSizes[size]}px;
  border-radius: ${({ size }) => badgeContainerSizes[size] / 2}px;
  border: ${({ outlined }) => (outlined ? 1 : 0)}px solid black;
`

export const Label = styled.Text<{ size: Size }>`
  font-family: ${({ theme }) => theme.fontFamily}-SemiBold;
  font-size: ${({ size }) => labelSizes[size]}px;
  color: white;
`

export const LabelUsername = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-Medium;
  font-size: 14px;
  color: black;
  width: 100%;
  text-align: center;
`
