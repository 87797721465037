import { gql } from '@apollo/client'
import {
  ThreadFragment,
  MessageFragment,
  UserFragment,
  PageInfoFragment,
  PostFragment,
  PostCommentFragment,
} from '../fragment'

export const THREAD = gql`
  query thread($threadId: String!, $first: Float!, $after: ID) {
    thread(threadId: $threadId) {
      ...ThreadFragment
      messages(pagination: { first: $first, after: $after }) {
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          node {
            ...MessageFragment
          }
        }
      }
    }
  }
  ${ThreadFragment}
  ${MessageFragment}
  ${PageInfoFragment}
`

export const THREADS = gql`
  query threads($first: Float!, $after: ID) {
    threads(pagination: { first: $first, after: $after }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          createdDate
          updatedDate
          author {
            ...UserFragment
          }
          recipient {
            ...UserFragment
          }
          notReadByAuthor
          notReadByRecipient
          messages(pagination: { first: 20 }) {
            pageInfo {
              ...PageInfoFragment
            }
            edges {
              node {
                ...MessageFragment
              }
            }
          }
        }
      }
    }
  }
  ${UserFragment}
  ${MessageFragment}
  ${PageInfoFragment}
`

export const FEED = gql`
  query feed($pagination: PaginationInput!, $filters: PostFiltersInput) {
    feed(pagination: $pagination, filters: $filters) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`

export const PUBLIC_FEED = gql`
  query publicFeed($pagination: PaginationInput!, $filters: PostFiltersInput) {
    publicFeed(pagination: $pagination, filters: $filters) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`

export const MY_POSTS = gql`
  query myPosts($pagination: PaginationInput!) {
    myPosts(pagination: $pagination) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`

export const MY_LIKED_POSTS = gql`
  query myLikedPosts($pagination: PaginationInput!) {
    myLikedPosts(pagination: $pagination) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`

export const MY_SOLVED_POSTS = gql`
  query mySolvedPosts($pagination: PaginationInput!) {
    mySolvedPosts(pagination: $pagination) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`

export const POST_COMMMENTS = gql`
  query postComments($postId: String!, $first: Float!, $after: ID) {
    postComments(postId: $postId, pagination: { first: $first, after: $after }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostCommentFragment
        }
      }
    }
  }
  ${PostCommentFragment}
  ${PageInfoFragment}
`
