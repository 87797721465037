import { gql } from '@apollo/client'

import { PostFragment } from '../fragment'

export const DAILY = gql`
  query daily {
    dailyPost {
      ...PostFragment
    }
  }
  ${PostFragment}
`
