import React, { useEffect } from 'react'
import { ImageSourcePropType } from 'react-native'

import { User } from '../../types'

import { useProfileImage } from '../../hooks/useProfileImage.hook'

import { getInitials, stringToColour } from '../../utils/badge'

import { Loader } from '../Loader'

import {
  Size,
  TouchableOpacity,
  BadgeContainer,
  Label,
  LabelUsername,
  ProfileImage,
  LoadingContainer,
} from './UserBadge.styled'

interface Props {
  sharedId: string
  user: User
  displayUsername?: boolean
  loading?: boolean
  usernameColor?: string
  size?: Size
  outlined?: boolean
  disabled?: boolean
}

export const UserBadge = (props: Props): JSX.Element => {
  const {
    user,
    displayUsername,
    loading,
    usernameColor = 'black',
    size = 'medium',
    outlined,
    disabled = true,
    sharedId,
  } = props

  const { firstName, lastName, username } = user

  const label = getInitials(firstName, lastName)
  const color = stringToColour(username)

  const { profileImage, downloadProfileImage } = useProfileImage(user, sharedId)

  useEffect(() => {
    downloadProfileImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profileImageUrl])

  const imgSource: ImageSourcePropType | undefined = profileImage?.path ? { uri: profileImage?.path } : undefined

  const loadingImage = loading || profileImage?.loading == 1

  return (
    <TouchableOpacity size={size} displayUsername={displayUsername} disabled={disabled}>
      {user.profileImageUrl ? (
        <React.Fragment>
          {imgSource && !loadingImage && <ProfileImage outlined={outlined} source={imgSource} size={size} />}

          {loadingImage && (
            <LoadingContainer size={size}>
              <Loader />
            </LoadingContainer>
          )}
        </React.Fragment>
      ) : (
        <BadgeContainer size={size} style={{ backgroundColor: color }} outlined={outlined}>
          <Label
            style={{
              textShadowColor: 'black',
              textShadowOffset: { width: 0.5, height: 0.5 },
              textShadowRadius: -1,
            }}
            size={size}
          >
            {label}
          </Label>
        </BadgeContainer>
      )}

      {displayUsername && (
        <LabelUsername style={{ color: usernameColor }} numberOfLines={1} ellipsizeMode="tail">
          {username}
        </LabelUsername>
      )}
    </TouchableOpacity>
  )
}
