const KEY_POST_SOLVED = 'POST_SOLVED'

export function savePostSolved(solved: boolean): void {
  localStorage.setItem(KEY_POST_SOLVED, `${solved}`)
}

export function loadPostSolved(): boolean {
  return localStorage.getItem(KEY_POST_SOLVED) == 'true'
}

export function clearPostSolved(): void {
  localStorage.removeItem(KEY_POST_SOLVED)
}
