import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
`

export const A = styled.a`
  margin: 0 10px;
`

// import styled from 'styled-components'
// import { Dimensions } from 'react-native'

// const isMobile = Dimensions.get('window').width <= 480

// export const Container = styled.footer`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0 20px;
//   box-sizing: border-box;
// `

// export const A = styled.a`
//   margin: 0 5px;

//   svg {
//     max-height: ${() => (isMobile ? 30 : 50)}px;
//   }
// `
