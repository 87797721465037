export const NotoSans = {
  'NotoSans-Black': require('../../assets/fonts/NotoSans/NotoSans-Black.ttf'),
  'NotoSans-BlackItalic': require('../../assets/fonts/NotoSans/NotoSans-BlackItalic.ttf'),
  'NotoSans-Bold': require('../../assets/fonts/NotoSans/NotoSans-Bold.ttf'),
  'NotoSans-BoldItalic': require('../../assets/fonts/NotoSans/NotoSans-BoldItalic.ttf'),
  'NotoSans-ExtraBold': require('../../assets/fonts/NotoSans/NotoSans-ExtraBold.ttf'),
  'NotoSans-ExtraBoldItalic': require('../../assets/fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf'),
  'NotoSans-ExtraLight': require('../../assets/fonts/NotoSans/NotoSans-ExtraLight.ttf'),
  'NotoSans-ExtraLightItalic': require('../../assets/fonts/NotoSans/NotoSans-ExtraLightItalic.ttf'),
  'NotoSans-Italic': require('../../assets/fonts/NotoSans/NotoSans-Italic.ttf'),
  'NotoSans-Light': require('../../assets/fonts/NotoSans/NotoSans-Light.ttf'),
  'NotoSans-LightItalic': require('../../assets/fonts/NotoSans/NotoSans-LightItalic.ttf'),
  'NotoSans-Medium': require('../../assets/fonts/NotoSans/NotoSans-Medium.ttf'),
  'NotoSans-MediumItalic': require('../../assets/fonts/NotoSans/NotoSans-MediumItalic.ttf'),
  'NotoSans-Regular': require('../../assets/fonts/NotoSans/NotoSans-Regular.ttf'),
  'NotoSans-SemiBold': require('../../assets/fonts/NotoSans/NotoSans-SemiBold.ttf'),
  'NotoSans-SemiBoldItalic': require('../../assets/fonts/NotoSans/NotoSans-SemiBoldItalic.ttf'),
  'NotoSans-Thin': require('../../assets/fonts/NotoSans/NotoSans-Thin.ttf'),
  'NotoSans-ThinItalic': require('../../assets/fonts/NotoSans/NotoSans-ThinItalic.ttf'),
}
