import * as Sentry from 'sentry-expo'

if (process.env.SENTRY_ENABLE == 'true') {
  if (!process.env.SENTRY_DSN) {
    throw new Error('SENTRY_DSN is missing')
  }

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    enableInExpoDevelopment: process.env.SENTRY_EXPO_DEVELOPMENT == 'true',
    debug: process.env.SENTRY_DEBUG == 'true',
  })
}

export { Sentry }
