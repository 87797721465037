import { gql } from '@apollo/client'

import { UserFragment } from '../fragment'

export const ME = gql`
  query me {
    me {
      ...UserFragment
      locale
      statistics {
        postsCount
        followersCount
        followingCount
        solvedPostsCount
      }
    }
  }
  ${UserFragment}
`
