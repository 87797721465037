import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Actions, AppState } from '../redux'
import { Post, LocalPost } from '../types'

export const useLocalPost = (
  post: Post,
  sharedId: string,
): {
  localPost?: LocalPost
  loadLocalPost: () => void
} => {
  const localPost: LocalPost | undefined = useSelector((state: AppState) =>
    state.postPreview.posts.find((p) => p.id == post.id),
  )

  const dispatch = useDispatch()

  function loadLocalPost() {
    dispatch(Actions.postPreview.addLocalPost(post, sharedId))
  }

  useEffect(() => {
    loadLocalPost()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { localPost, loadLocalPost }
}
