import styled from 'styled-components/native'

export const Container = styled.View``

export const GridPreview = styled.View`
  position: relative;
`

export const SquarePreviewContainer = styled.TouchableOpacity<{ selected?: boolean }>`
  position: absolute;
  padding: 1px;
  z-index: ${({ selected }) => (selected ? 1 : 0)};
`

export const SquarePreview = styled.Image<{ selected?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  opacity: ${({ selected }) => (selected ? 0.5 : 1)};
`

export const LockContainer = styled.View`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TouchableOpacity = styled.TouchableOpacity``

export const ImagePreview = styled.Image`
  width: 100%;
  height: 100%;
`
