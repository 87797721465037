import { LocalPost } from '../../types'

export const POST_PREVIEW_ADD_LOCAL_POST = '@@post_preview/POST_PREVIEW_ADD_LOCAL_POST'

export interface AddLocalPost {
  type: typeof POST_PREVIEW_ADD_LOCAL_POST
  post: LocalPost
}

export type PostActionTypes = AddLocalPost
