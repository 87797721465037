import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 34 41" width={34} height={41} fill="none" {...props}>
    <Path
      // eslint-disable-next-line max-len
      d="M12.5 2.75c-.3 0-.558-.108-.775-.325A1.118 1.118 0 0 1 11.4 1.6c0-.3.108-.558.325-.775C11.942.608 12.2.5 12.5.5h9c.3 0 .558.108.775.325.217.217.325.492.325.825 0 .333-.108.6-.325.8-.217.2-.475.3-.775.3h-9Zm4.5 22.4c.333 0 .608-.108.825-.325.217-.217.325-.492.325-.825v-8.6c0-.3-.108-.567-.325-.8a1.077 1.077 0 0 0-.825-.35c-.333 0-.608.117-.825.35-.217.233-.325.5-.325.8V24c0 .333.108.608.325.825.217.217.492.325.825.325Zm0 15.8c-2.333 0-4.533-.45-6.6-1.35-2.067-.9-3.867-2.117-5.4-3.65-1.533-1.533-2.75-3.333-3.65-5.4C.45 28.483 0 26.283 0 23.95c0-2.333.45-4.525 1.35-6.575.9-2.05 2.117-3.85 3.65-5.4C6.533 10.425 8.333 9.2 10.4 8.3c2.067-.9 4.267-1.35 6.6-1.35 2.133 0 4.15.375 6.05 1.125 1.9.75 3.6 1.775 5.1 3.075L29.7 9.6c.2-.2.45-.3.75-.3s.567.117.8.35c.233.233.35.5.35.8 0 .3-.117.567-.35.8l-1.5 1.5c1.2 1.333 2.208 2.933 3.025 4.8.817 1.867 1.225 4 1.225 6.4 0 2.333-.45 4.533-1.35 6.6-.9 2.067-2.117 3.867-3.65 5.4-1.533 1.533-3.333 2.75-5.4 3.65-2.067.9-4.267 1.35-6.6 1.35Zm0-2.25c4.1 0 7.583-1.433 10.45-4.3 2.867-2.867 4.3-6.35 4.3-10.45 0-4.067-1.433-7.533-4.3-10.4S21.1 9.25 17 9.25s-7.583 1.433-10.45 4.3c-2.867 2.867-4.3 6.333-4.3 10.4 0 4.1 1.433 7.583 4.3 10.45 2.867 2.867 6.35 4.3 10.45 4.3Z"
      fill="#000"
    />
  </Svg>
)

export default SvgComponent
