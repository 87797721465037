import React from 'react'

import TimerImage from '../../../assets/images/timer/timer.component'

import { Container, CircleTimeConainer, CircleTimeLabel } from './Timer.styled'

interface Props {
  time: number
  isSolved: boolean
  onReachedTimeout: () => void
}

interface State {
  currentTime: number
}

export class Timer extends React.Component<Props, State> {
  interval?: NodeJS.Timeout

  constructor(props: Props) {
    super(props)

    const { time } = this.props

    this.state = {
      currentTime: time,
    }

    this.configureTimer()
  }

  componentDidUpdate(prevProps: Props): void {
    const { isSolved: prevIsSolved } = prevProps

    const { isSolved } = this.props

    if (prevIsSolved !== isSolved && isSolved) {
      this.clearTimer()
    }
  }

  componentWillUnmount(): void {
    this.clearTimer()
  }

  configureTimer = (): void => {
    this.interval = setInterval(this.updateCurrentTime, 1000)
  }

  clearTimer = (): void => {
    if (this.interval !== undefined) {
      clearInterval(this.interval)
    }
  }

  updateCurrentTime = (): void => {
    const { currentTime } = this.state

    const newCurrentTime = currentTime - 1

    this.setState({ currentTime: newCurrentTime }, () => {
      if (newCurrentTime === 0) {
        this.clearTimer()
        const { onReachedTimeout } = this.props
        onReachedTimeout()
      }
    })
  }

  render(): JSX.Element {
    const { currentTime } = this.state

    return (
      <Container>
        <CircleTimeConainer>
          <TimerImage width={30} />

          <CircleTimeLabel>{currentTime}</CircleTimeLabel>
        </CircleTimeConainer>
      </Container>
    )
  }
}
