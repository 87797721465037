import { PROFILE_IMAGE_DOWNLOAD, ProfileImageActionTypes } from './actionTypes'

import { ProfileImage } from '../../types'

export interface ProfileImageState {
  images: ProfileImage[]
}

const defaultState: ProfileImageState = {
  images: [],
}

export const profileImage = (
  state: ProfileImageState = defaultState,
  action: ProfileImageActionTypes,
): ProfileImageState => {
  switch (action.type) {
    case PROFILE_IMAGE_DOWNLOAD: {
      const images = [...state.images.filter((image) => image.id !== action.image.id), action.image]
      return {
        ...state,
        images,
      }
    }
    default:
      return state
  }
}
