import { RankingSorting } from '../../types'

export const APP_SET_SEARCH_QUERY = '@@app/SET_SEARCH_QUERY'

export interface SetSearchQuery {
  type: typeof APP_SET_SEARCH_QUERY
  query: string
}

export const APP_SET_HOME_TAB = '@@app/SET_HOME_TAB'

export interface SetHomeTab {
  type: typeof APP_SET_HOME_TAB
  tab: 'feed' | 'daily'
}

export const APP_SET_RANKING_TAB = '@@app/SET_RANKING_TAB'

export interface SetRankingTab {
  type: typeof APP_SET_RANKING_TAB
  tab: RankingSorting
}

export const APP_SET_POST_LIST_FILTER = '@@app/SET_POST_LIST_FILTER'

export interface SetPostListFilter {
  type: typeof APP_SET_POST_LIST_FILTER
  filter?: 'solved' | 'unsolved'
}

export type AppActionTypes = SetSearchQuery | SetHomeTab | SetRankingTab | SetPostListFilter
