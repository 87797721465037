import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 45 42" width={45} height={42} fill="none" {...props}>
    <Path
      // eslint-disable-next-line max-len
      d="m22.25 40.65-2.9-2.6c-3.633-3.333-6.625-6.208-8.975-8.625-2.35-2.417-4.225-4.583-5.625-6.5-1.4-1.917-2.375-3.667-2.925-5.25A14.61 14.61 0 0 1 1 12.85C1 9.483 2.125 6.667 4.375 4.4 6.625 2.133 9.417 1 12.75 1c1.867 0 3.6.4 5.2 1.2 1.6.8 3.033 1.983 4.3 3.55 1.467-1.667 2.967-2.875 4.5-3.625S29.95 1 31.75 1c3.367 0 6.183 1.133 8.45 3.4 2.267 2.267 3.4 5.083 3.4 8.45 0 1.633-.275 3.233-.825 4.8-.55 1.567-1.525 3.308-2.925 5.225-1.4 1.917-3.283 4.092-5.65 6.525-2.367 2.433-5.367 5.317-9 8.65l-2.95 2.6Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2}
    />
  </Svg>
)

export default SvgComponent
