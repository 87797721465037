import { Square } from './generated/graphql'

export enum ShapeType {
  square = 'square',
  rectangle = 'rectangle',
}

export type Time = 'infinite' | '10' | '20' | '30' | '45' | '60' | '90' | '120'

export type Shape = {
  key: string
  label: string
  horizontal: number
  vertical: number
  maxSquaresToHide: number
  durations: Time[]
}

export type Coordinate = {
  x: number
  y: number
}

export type ImageSize = {
  width: number
  height: number
}

export type GridFormat = {
  x: number
  y: number
}

export type SquareImageUrl = {
  [key: string]: string
}

export type DraftSquare = Square & {
  hidden: boolean
}
