export async function downloadImage(url: string): Promise<string | undefined> {
  try {
    const base64 = await downloadToBase64(url)
    return base64
  } catch (error) {
    console.error(error)
  }

  return undefined
}

export function downloadToBase64(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('GET', url, true)
    request.responseType = 'blob'
    request.onload = function () {
      const reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result as string)
      }

      reader.onerror = () => {
        reject()
      }

      reader.readAsDataURL(request.response)
    }
    request.send()
  })
}
