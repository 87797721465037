import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Localization from 'expo-localization'

import { saveData, loadData } from '../utils/storage'

import en from './en.json'
import fr from './fr.json'

export type Language = 'en' | 'fr'

export const languages: Language[] = ['en', 'fr']

export function getDefaultLangue(): Language {
  const defaultLanguage = Localization.locale.split('-')[0]
  return languages.find((language) => language == defaultLanguage) || 'en'
}

export function configurei18n(lng: Language): void {
  // eslint-disable-next-line import/no-named-as-default-member
  i18next.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng,
    // debug: true,
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
  })
}

export interface DataI18n {
  currentLanguage: Language
}

const KEY_I18N = '18N'

export async function saveI18n(data: DataI18n): Promise<void> {
  saveData<DataI18n>(KEY_I18N, data)
}

export async function loadI18n(): Promise<DataI18n | undefined> {
  return loadData<DataI18n>(KEY_I18N)
}
