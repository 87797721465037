import { Dimensions } from 'react-native'
import * as ImageManipulator from 'expo-image-manipulator'

import { GridFormat, DraftSquare, Coordinate, ImageSize, SquareImageUrl, ShapeType, Square } from '../types'

export function getDraftSquares(gridFormat: GridFormat): DraftSquare[] {
  const { y: vertical, x: horizontal } = gridFormat

  const draftSquares: DraftSquare[] = []

  for (let y = 0; y < vertical; y++) {
    for (let x = 0; x < horizontal; x++) {
      const index = x + y * horizontal
      const coordinates = { index, reverseIndex: undefined, hidden: false }
      draftSquares.push(coordinates)
    }
  }

  return draftSquares
}

function getRandomIndex(length: number) {
  return Math.floor(Math.random() * Math.floor(length))
}

export function getShuffleSquares(squares: DraftSquare[]): DraftSquare[] {
  let visibleSquares = squares.filter((s) => !s.hidden)

  const shuffledSquares = squares.map((square) => {
    if (!square.hidden) {
      const randomIndex = getRandomIndex(visibleSquares.length)
      const squareForIndex = visibleSquares[randomIndex]
      visibleSquares = visibleSquares.filter((s) => s.index !== squareForIndex.index)
      return { ...square, reverseIndex: squareForIndex.index }
    }
    return square
  })

  return shuffledSquares
}

export function isSquaresShuffled(squares: Square[]): boolean {
  const squareWithReverseIndex = squares.find((s) => s.reverseIndex !== undefined && s.reverseIndex !== null)
  return !!squareWithReverseIndex
}

export function getGridCoordinates(gridFormat: GridFormat): Coordinate[] {
  const { y: vertical, x: horizontal } = gridFormat

  const gridRepresentation: Coordinate[] = []

  for (let y = 0; y < vertical; y++) {
    for (let x = 0; x < horizontal; x++) {
      const coordinates = { x, y }
      gridRepresentation.push(coordinates)
    }
  }

  return gridRepresentation
}

export async function getCroppedImageURI(
  originY: number,
  originX: number,
  width: number,
  height: number,
  uri: string,
): Promise<string> {
  const manipResult = await ImageManipulator.manipulateAsync(uri, [{ crop: { originX, originY, width, height } }], {
    compress: 1,
    format: ImageManipulator.SaveFormat.JPEG,
  })

  return manipResult.uri
}

export function getKeyFromCoordinate(coordinate: Coordinate): string {
  return `${coordinate.y}-${coordinate.x}`
}

export async function getSquareImageUrl(
  imageSize: ImageSize,
  gridCoordinates: Coordinate[],
  gridFormat: GridFormat,
  imageUrl: string,
): Promise<SquareImageUrl> {
  const width = imageSize.width / gridFormat.x
  const height = imageSize.height / gridFormat.y

  const squareImageUrl: SquareImageUrl = {}

  for (const coordinate of gridCoordinates) {
    const imageUri = await getCroppedImageURI(coordinate.y * height, coordinate.x * width, width, height, imageUrl)
    const key = getKeyFromCoordinate(coordinate)
    squareImageUrl[key] = imageUri
  }

  return squareImageUrl
}

export function getViewSize(shapeType: ShapeType): ImageSize {
  const screenWidth = Math.round(Dimensions.get('window').width)

  return {
    width: screenWidth,
    height: shapeType === ShapeType.rectangle ? Math.round((screenWidth / 3) * 4) : screenWidth,
  }
}
