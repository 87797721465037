import { AppStore } from '../../components/AppStore'

import { Container, Icon, SectionContainer } from './Splash.styled'
import { Loader } from '../../components/Loader'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  init?: boolean
}

export const SplashScreen = (props: Props): JSX.Element => {
  const { init } = props

  const { i18n } = useTranslation()

  useEffect(() => {
    if (!init) {
      const path = i18n.language == 'en' ? '' : `/${i18n.language}`
      window.location.href = `https://shufflin.co${path}`
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <SectionContainer>
        <Icon source={require('../../../assets/images/icon.png')} />
        <SectionContainer />
      </SectionContainer>

      <SectionContainer>
        <Loader />
      </SectionContainer>

      <SectionContainer>
        <SectionContainer />
        <AppStore />
      </SectionContainer>
    </Container>
  )
}
