import { gql } from '@apollo/client'

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    createdDate
    updatedDate
    username
    firstName
    lastName
    email
    role
    profileImageUrl
    isPublic
  }
`

export const FollowerFragment = gql`
  fragment FollowerFragment on Follower {
    id
    user {
      ...UserFragment
    }
    following
    followed
    pending
    pended
    blocking
  }
  ${UserFragment}
`

export const PostRankingFragment = gql`
  fragment PostRankingFragment on FollowerRank {
    follower {
      ...FollowerFragment
    }
    rank
  }
  ${FollowerFragment}
`

export const SharedPostFragment = gql`
  fragment SharedPostFragment on Post {
    id
    createdDate
    authorId
    author {
      ...UserFragment
    }
    caption
    isPublic
    statistics {
      countOfLikes
      countOfSolved
      countOfComments
    }
    imageUrl
    grid {
      timeToSolve
      x
      y
      squares {
        index
        reverseIndex
      }
    }
  }
  ${UserFragment}
`

export const PostFragment = gql`
  fragment PostFragment on Post {
    id
    createdDate
    authorId
    author {
      ...UserFragment
    }
    caption
    isPublic
    state {
      status
      like
    }
    statistics {
      countOfLikes
      countOfSolved
      countOfComments
    }
    imageUrl
    grid {
      timeToSolve
      x
      y
      squares {
        index
        reverseIndex
      }
    }
  }
  ${UserFragment}
`

export const ThreadFragment = gql`
  fragment ThreadFragment on Thread {
    id
    createdDate
    updatedDate
    author {
      ...UserFragment
    }
    recipient {
      ...UserFragment
    }
    notReadByAuthor
    notReadByRecipient
  }
  ${UserFragment}
`

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    id
    createdDate
    content
    threadId
    authorId
    recipientId
    read
    deletedByAuthor
    deletedByRecipient
    post {
      ...PostFragment
    }
    recipientState {
      status
      like
    }
  }
  ${PostFragment}
`

export const PostCommentFragment = gql`
  fragment PostCommentFragment on PostComment {
    id
    postId
    createdDate
    content
    author {
      ...UserFragment
    }
  }
  ${UserFragment}
`
