import { Post, PostSolvingStatus } from '../../types'

import { ViewPostScreenProps } from '../../navigators/MainStack'

import { useSharedPost } from '../../hooks/useSharedPost.hook'

import { Refresh } from '../../components/Refresh'
import { PostItem } from '../../components/PostItem'
import { AppStore } from '../../components/AppStore'

import { Container, SectionContainer } from './ViewPost.styled'
import { loadPostSolved } from '../../utils/post-solved'

type Props = ViewPostScreenProps

export const ViewPostScreen = (props: Props): JSX.Element => {
  const {
    route: {
      params: { sharedId },
    },
  } = props

  const { data, loading, error, refetch } = useSharedPost(sharedId)

  const post: Post | undefined = data?.sharedPost && {
    ...data.sharedPost,
    state: data.sharedPost.state || {
      like: false,
      attemptToSolve: 0,
      timeToSolve: 0,
      status: loadPostSolved() ? PostSolvingStatus.Solved : PostSolvingStatus.Pending,
    },
  }

  const isLoading = loading ? 1 : error ? -1 : 0

  return (
    <Container>
      <SectionContainer />
      <Refresh loading={isLoading} onRefresh={() => refetch(sharedId)} small>
        {post ? <PostItem item={post} sharedId={sharedId} /> : undefined}
      </Refresh>

      <SectionContainer>
        <SectionContainer />
        <AppStore />
      </SectionContainer>
    </Container>
  )
}
