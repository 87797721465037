import { useRoute } from '@react-navigation/native'

import { useSharedPost } from '../../hooks/useSharedPost.hook'
import { ResolvePostScreenProps } from '../../navigators/MainStack'

import { UserBadge } from '../UserBadge'

import { Container } from './HeaderResolvePostScreenFollowerProfile.styled'

interface Props {
  disabled?: boolean
}

export const HeaderResolvePostScreenFollowerProfile = (props: Props): JSX.Element => {
  const { disabled } = props

  const route = useRoute<ResolvePostScreenProps['route']>()

  const { data } = useSharedPost(route.params.sharedId)

  const user = data?.sharedPost?.author

  return (
    <Container>
      {user && (
        <UserBadge sharedId={route.params.sharedId} user={user} size="extra-small" outlined disabled={disabled} />
      )}
    </Container>
  )
}
