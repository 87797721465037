import styled from 'styled-components/native'

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e24054;
  justify-content: space-between;
  margin: 10px 0;
`

export const Label = styled.Text`
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fontFamily}-SemiBold;
  color: black;
  text-align: center;
`
