import React, { useState } from 'react'

import { savePostSolved } from '../../utils/post-solved'

import { Post, PostSolvingStatus } from '../../types'

import { ResolvePostScreenProps } from '../../navigators/MainStack'

import { useSharedPost } from '../../hooks/useSharedPost.hook'
import { useLocalPost } from '../../hooks/useLocalPost.hook'

import { ResolveGrid } from '../../components/ResolveGrid'
import { Timer } from '../../components/Timer'
import { Refresh } from '../../components/Refresh'

import { Container, SectionContainer } from './ResolvePost.styled'

type Props = ResolvePostScreenProps

interface ResolvePostProps extends ResolvePostScreenProps {
  post: Post
  sharedId: string
}

const ResolvePost = (props: ResolvePostProps) => {
  const {
    post,
    sharedId,
    navigation: { navigate },
  } = props

  const {
    state: { status },
  } = post

  const meIsAuthor = false

  const [solved, setSolved] = useState<boolean>(status == PostSolvingStatus.Solved)

  const { localPost, loadLocalPost } = useLocalPost(post, sharedId)

  const onSolved = (_attempt: number) => {
    setSolved(true)

    savePostSolved(true)

    navigate('ViewPostScreen', { sharedId })
  }

  const onReachedTimeout = () => {
    navigate('ViewPostScreen', { sharedId })
  }

  const { grid } = post

  const isLoading = localPost?.loadingImage == undefined ? 0 : localPost?.loadingImage

  return (
    <Container>
      <SectionContainer>
        {!(meIsAuthor || solved) && grid?.timeToSolve && (
          <Timer time={grid.timeToSolve} isSolved={solved} onReachedTimeout={onReachedTimeout} />
        )}
      </SectionContainer>

      <Refresh loading={isLoading} onRefresh={loadLocalPost} small>
        {localPost?.image ? (
          <ResolveGrid post={post} localPost={localPost} isSolved={meIsAuthor || solved} onSolved={onSolved} />
        ) : undefined}
      </Refresh>

      <SectionContainer />
    </Container>
  )
}

export const ResolvePostScreen = (props: Props): JSX.Element => {
  const {
    route: {
      params: { sharedId },
    },
  } = props

  const { data, loading, error, refetch } = useSharedPost(sharedId)

  const post: Post | undefined = data?.sharedPost && {
    ...data.sharedPost,
    state: data.sharedPost.state || {
      like: false,
      attemptToSolve: 0,
      timeToSolve: 0,
      status: PostSolvingStatus.Pending,
    },
  }

  const isLoading = loading ? 1 : error ? -1 : 0

  return (
    <Container>
      <Refresh loading={isLoading} onRefresh={() => refetch(sharedId)} small>
        {post ? <ResolvePost post={post} sharedId={sharedId} {...props} /> : undefined}
      </Refresh>
    </Container>
  )
}
