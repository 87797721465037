import { POST_PREVIEW_ADD_LOCAL_POST, PostActionTypes } from './actionTypes'

import { LocalPost } from '../../types'

export interface PostPreviewState {
  posts: LocalPost[]
}

const defaultState: PostPreviewState = {
  posts: [],
}

export const postPreview = (state: PostPreviewState = defaultState, action: PostActionTypes): PostPreviewState => {
  switch (action.type) {
    case POST_PREVIEW_ADD_LOCAL_POST: {
      const posts = [...state.posts.filter((m) => m.id !== action.post.id), action.post]
      return {
        ...state,
        posts,
      }
    }
    default:
      return state
  }
}
