import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 37 47" width={37} height={47} fill="none" {...props}>
    <Path
      // eslint-disable-next-line max-len
      d="M5.7 14.9h17.9v-3.7c0-1.567-.508-2.867-1.525-3.9s-2.292-1.55-3.825-1.55c-1.5 0-2.767.517-3.8 1.55s-1.55 2.333-1.55 3.9H8.2c0-2.833.975-5.242 2.925-7.225C13.075 1.992 15.45 1 18.25 1s5.175.992 7.125 2.975C27.325 5.958 28.3 8.367 28.3 11.2v3.7h2.5c1.3 0 2.417.467 3.35 1.4.933.933 1.4 2.05 1.4 3.35v20.8c0 1.3-.467 2.408-1.4 3.325-.933.917-2.05 1.375-3.35 1.375H5.7c-1.3 0-2.408-.458-3.325-1.375C1.458 42.858 1 41.75 1 40.45v-20.8c0-1.3.458-2.417 1.375-3.35.917-.933 2.025-1.4 3.325-1.4Zm12.55 19c1.067 0 1.975-.367 2.725-1.1.75-.733 1.125-1.617 1.125-2.65 0-1-.375-1.908-1.125-2.725-.75-.817-1.658-1.225-2.725-1.225-1.067 0-1.975.408-2.725 1.225-.75.817-1.125 1.725-1.125 2.725 0 1.033.375 1.917 1.125 2.65.75.733 1.658 1.1 2.725 1.1Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2}
    />
  </Svg>
)

export default SvgComponent
