type Operation<T> = () => Promise<T>

export class Queue<T> {
  private _store: Operation<T>[] = []

  private _processing = false
  private _pause = false

  push(operation: Operation<T>): void {
    this._store.push(operation)
    this.execute()
  }

  pause(): void {
    this._pause = true
  }

  resume(): void {
    if (this._pause) {
      this._pause = false
      this.run()
    }
  }

  private async execute() {
    if (!this._processing) {
      this._processing = true
      await this.run()
      this._processing = false
    }
  }

  private async run() {
    let operation = this._store.shift()
    while (operation) {
      await operation()
      if (!this._pause) operation = this._store.shift()
      else operation = undefined
    }
  }
}
