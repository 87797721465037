import { useTranslation } from 'react-i18next'

import { NotFoundScreenProps } from '../../navigators/RootStack/RootStack.params'

import { AppStore } from '../../components/AppStore'

import { Container, Icon, SectionContainer, LabelContainer, Label404, LabelNotFound } from './NotFound.styled'

export const NotFoundScreen = (_props: NotFoundScreenProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container>
      <SectionContainer>
        <Icon source={require('../../../assets/images/icon.png')} />
        <SectionContainer />
      </SectionContainer>

      <SectionContainer>
        <LabelContainer>
          <Label404>{404}</Label404>
          <LabelNotFound>{t('screens_NotFound_title')}</LabelNotFound>
        </LabelContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionContainer />
        <AppStore />
      </SectionContainer>
    </Container>
  )
}
