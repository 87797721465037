import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 38 44" width={38} height={44} fill="none" {...props}>
    <Path
      // eslint-disable-next-line max-len
      d="M13 4V1h12v3H13Zm4.5 22.35h3v-11.5h-3v11.5Zm1.5 16.6c-2.467 0-4.792-.475-6.975-1.425A18.387 18.387 0 0 1 6.3 37.65a18.386 18.386 0 0 1-3.875-5.725C1.475 29.742 1 27.417 1 24.95c0-2.467.475-4.792 1.425-6.975A18.386 18.386 0 0 1 6.3 12.25a18.387 18.387 0 0 1 5.725-3.875C14.208 7.425 16.533 6.95 19 6.95c2.233 0 4.325.367 6.275 1.1a17.022 17.022 0 0 1 5.225 3.1l2.55-2.55 2.15 2.15-2.55 2.55c1.2 1.333 2.225 2.95 3.075 4.85.85 1.9 1.275 4.167 1.275 6.8 0 2.467-.475 4.792-1.425 6.975A18.387 18.387 0 0 1 31.7 37.65a18.387 18.387 0 0 1-5.725 3.875c-2.183.95-4.508 1.425-6.975 1.425Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
)

export default SvgComponent
