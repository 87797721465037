import { Post, PostSolvingStatus } from '../../types'

import Heart from '../../../assets/images/components/heart.component'
import HeartFilled from '../../../assets/images/components/heart-filled.component'

import CommentFilled from '../../../assets/images/components/comment-filled.component'

import LockOpen from '../../../assets/images/components/lock-open.component'
import LockClosed from '../../../assets/images/components/lock-closed.component'

import { Container, TouchableOpacity, Label } from './PostActionBar.styled'

interface Props {
  meIsAuthor: boolean
  post: Post
  onPressComment?: () => void
  onPressLock?: () => void
}

export const PostActionBar = (props: Props): JSX.Element => {
  const { post, onPressComment, onPressLock } = props

  const { countOfLikes, countOfComments, countOfSolved } = post.statistics

  const isLiking = post.state.like
  const isSolved = post.state.status == PostSolvingStatus.Solved

  return (
    <Container>
      <TouchableOpacity disabled>
        {isLiking ? <HeartFilled width={32} height={32} /> : <Heart width={32} height={32} />}
        <Label
          style={{ textShadowColor: 'black', textShadowOffset: { width: 0.5, height: 0.5 }, textShadowRadius: -1 }}
        >
          {countOfLikes ? countOfLikes : null}
        </Label>
      </TouchableOpacity>

      <TouchableOpacity disabled={!onPressComment} onPress={onPressComment}>
        <CommentFilled width={32} height={32} />
        <Label
          style={{ textShadowColor: 'black', textShadowOffset: { width: 0.5, height: 0.5 }, textShadowRadius: -1 }}
        >
          {countOfComments > 0 ? countOfComments : null}
        </Label>
      </TouchableOpacity>

      <TouchableOpacity disabled={!onPressLock} onPress={onPressLock}>
        {isSolved ? <LockOpen width={34} height={34} /> : <LockClosed width={34} height={34} />}
        <Label
          style={{ textShadowColor: 'black', textShadowOffset: { width: 0.5, height: 0.5 }, textShadowRadius: -1 }}
        >
          {countOfSolved > 0 ? countOfSolved : null}
        </Label>
      </TouchableOpacity>
    </Container>
  )
}
