import * as Font from 'expo-font'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'

import { NotoSans } from './utils/font'

import { clearPostSolved } from './utils/post-solved'

import { configurei18n, loadI18n, getDefaultLangue } from './translations'

import { configureStore, ReduxStore } from './redux'

import { configureClient, ApolloClient } from './apollo/client'

import { AppStorage } from './App.storage'
import { AppEvent } from './App.event'

export class AppManager {
  private static instance: AppManager

  public readonly reduxStore: ReduxStore
  public readonly apolloClient: ApolloClient

  private constructor(_reduxStore: ReduxStore, _apolloClient: ApolloClient) {
    clearPostSolved()

    this.reduxStore = _reduxStore
    this.apolloClient = _apolloClient

    AppEvent.subscribeSignOut(() => {
      AppManager.getInstance().signOut()
    })
  }

  public static async getAsyncInstance(): Promise<AppManager> {
    if (!AppManager.instance) {
      await Font.loadAsync(NotoSans)

      const i18n = await loadI18n()
      const locale = i18n?.currentLanguage || getDefaultLangue()
      configurei18n(locale)
      moment.locale(locale)

      await AppStorage.getAsyncInstance()

      const reduxStore = await configureStore()
      const apolloClient = await configureClient()

      AppManager.instance = new AppManager(reduxStore, apolloClient)
    }

    return AppManager.instance
  }

  public static getInstance(): AppManager {
    if (AppManager.instance) {
      return AppManager.instance
    }

    throw new Error('Please use getAsyncInstance first')
  }

  public async signOut(): Promise<void> {
    AppStorage.getInstance().clearAuthInfos()

    await this.apolloClient.clearStore()
  }
}
