import { useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { useSharedPost } from '../../hooks/useSharedPost.hook'
import { ResolvePostScreenProps } from '../../navigators/MainStack'

import { Container, A, Icon, Label } from './HeaderResolvePostScreenTitle.styled'

export const HeaderResolvePostScreenTitle = (): JSX.Element => {
  const route = useRoute<ResolvePostScreenProps['route']>()

  const { data } = useSharedPost(route.params.sharedId)

  const user = data?.sharedPost?.author

  const { i18n } = useTranslation()

  const path = i18n.language == 'en' ? '' : `/${i18n.language}`
  const href = `https://shufflin.co${path}`

  return (
    <Container>
      <A href={href}>
        <Icon source={require('../../../assets/images/icon.png')} />
      </A>

      {user && <Label>{user.firstName}</Label>}
    </Container>
  )
}
