import { ActivityIndicator, ActivityIndicatorProps } from 'react-native'

import { Container } from './Loader.styled'

interface Props {
  size?: ActivityIndicatorProps['size']
}

const colors = ['#FFC312', '#C4E538', '#12CBC4', '#EA2027', '#FDA7DF', '#1B1464', '#6F1E51', '#ED4C67']

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min)) + Math.ceil(min))
}

export const Loader = (props: Props): JSX.Element => {
  const { size = 'small' } = props

  const color = colors[getRandomInt(0, colors.length - 1)]

  return (
    <Container>
      <ActivityIndicator size={size} color={color} />
    </Container>
  )
}
