import { useEffect, useState } from 'react'
import { StatusBar } from 'expo-status-bar'
import { ThemeProvider } from 'styled-components/native'
import { Provider as ReduxProvider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import * as ExpoSplashScreen from 'expo-splash-screen'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import { Sentry } from './analytics/sentry'
import './analytics/firebase'

import { theme } from './theme'

import { SplashScreen } from './screens/Splash'
import { RootStack } from './navigators/RootStack'
import { AppManager } from './App.manager'

import { ReduxStore } from './redux'
import { ApolloClient } from './apollo/client'

ExpoSplashScreen.preventAutoHideAsync()

export const App = (): JSX.Element => {
  const [appLoaded, setAppLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!appLoaded) {
      configure()
    }
  }, [appLoaded])

  const configure = async (): Promise<void> => {
    try {
      await AppManager.getAsyncInstance()

      setAppLoaded(true)
    } catch (error) {
      Sentry.Browser.captureException(error)
    }
  }

  const state: { reduxStore?: ReduxStore; apolloClient?: ApolloClient } = {
    reduxStore: undefined,
    apolloClient: undefined,
  }

  try {
    if (appLoaded) {
      state.reduxStore = AppManager.getInstance().reduxStore
      state.apolloClient = AppManager.getInstance().apolloClient
    }
  } catch (error) {
    setAppLoaded(false)
  }

  const { reduxStore, apolloClient } = state

  useEffect(() => {
    if (appLoaded) {
      ExpoSplashScreen.hideAsync()
    }
  }, [appLoaded])

  return (
    <SafeAreaProvider>
      <ThemeProvider theme={theme}>
        {appLoaded && reduxStore && apolloClient ? (
          <ReduxProvider store={reduxStore}>
            <ApolloProvider client={apolloClient}>
              <RootStack />
            </ApolloProvider>
          </ReduxProvider>
        ) : (
          <SplashScreen init />
        )}

        <StatusBar style="dark" />
      </ThemeProvider>
    </SafeAreaProvider>
  )
}
