import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Actions, AppState } from '../redux'
import { User, ProfileImage } from '../types'

export const useProfileImage = (
  user: User,
  sharedId: string,
): {
  profileImage?: ProfileImage
  downloadProfileImage: () => void
} => {
  const paths = user.profileImageUrl?.split('/')
  const imageId = paths && paths[paths.length - 1]

  const profileImage: ProfileImage | undefined = useSelector((state: AppState) =>
    state.profileImage.images.find((i) => i.id == imageId),
  )

  const dispatch = useDispatch()

  function downloadProfileImage() {
    dispatch(Actions.profileImage.downloadProfileImage(user, sharedId))
  }

  useEffect(() => {
    downloadProfileImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { profileImage, downloadProfileImage }
}
