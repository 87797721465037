import { gql } from '@apollo/client'

import { UserFragment, FollowerFragment } from '../fragment'

export const FOLLOWER = gql`
  query follower($userId: String!) {
    follower(userId: $userId) {
      id
      user {
        ...UserFragment
        statistics {
          postsCount
          followersCount
          followingCount
          solvedPostsCount
        }
      }
      following
      followed
      pending
      pended
      blocking
    }
  }
  ${UserFragment}
`

export const PENDING_FOLLOWERS = gql`
  query pendingFollowers($first: Float!, $after: ID) {
    pendingFollowers(pagination: { first: $first, after: $after }) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FollowerFragment
        }
      }
    }
  }
  ${FollowerFragment}
`

export const FOLLOWERS = gql`
  query followers($first: Float!, $after: ID, $userId: String) {
    followers(pagination: { first: $first, after: $after }, userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FollowerFragment
        }
      }
    }
  }
  ${FollowerFragment}
`

export const FOLLOWING = gql`
  query following($first: Float!, $after: ID, $userId: String) {
    following(pagination: { first: $first, after: $after }, userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FollowerFragment
        }
      }
    }
  }
  ${FollowerFragment}
`

export const BLOCKED_FOLLOWERS = gql`
  query blockedFollowers($first: Float!, $after: ID) {
    blockedFollowers(pagination: { first: $first, after: $after }) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FollowerFragment
        }
      }
    }
  }
  ${FollowerFragment}
`
