import styled from 'styled-components/native'

export const Container = styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px 5px 5px;
  height: 46px;
`

export const BottomContainer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 4px 8px 8px;
  min-height: 56px;
`

export const DescriptionContainer = styled.View`
  flex: 1;
  padding-top: 2px;
`

export const LabelCaption = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-Regular;
  font-size: 12px;
  color: black;
  padding-bottom: 5px;
`

export const UserContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const UserInfosContainer = styled.View`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
`

export const TrophyContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TimerContainer = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LabelTimer = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-SemiBold;
  font-size: 16px;
  color: white;
  padding-right: 5px;
`

export const ActionContainer = styled.View`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 8px;
`

export const UserLabel = styled.Text`
  color: black;
  font-family: ${({ theme }) => theme.fontFamily}-SemiBold;
  font-size: 14px;
`

export const PreviewContainer = styled.View`
  position: relative;
  z-index: 1;
`

export const DateContainer = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
`

export const LabelDate = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-Bold;
  font-size: 10px;
  color: white;
  text-align: left;
`
