import React, { useRef, useState } from 'react'
import { Dimensions } from 'react-native'

import { Lock } from '../../components/Lock'

import { Post, Square, LocalPost, Coordinate } from '../../types'

import {
  Container,
  GridPreview,
  SquarePreviewContainer,
  LockContainer,
  SquarePreview,
  ImagePreview,
} from './ResolveGrid.styled'

interface Size {
  width: number
  height: number
}

interface SwappedSquare extends Square {
  swappedIndex?: number | null
}

interface Props {
  post: Post
  localPost: LocalPost
  isSolved?: boolean
  onSolved?: (attempt: number) => void
}

interface State {
  squares: SwappedSquare[]
  squareToSwap?: SwappedSquare
  reverseSquareToSwap?: SwappedSquare
  coordinates?: Coordinate
  hiddenSquareIndex?: number
}

export const ResolveGrid = (props: Props): JSX.Element => {
  const { post, isSolved, localPost } = props

  const { grid } = post

  const refAttempt = useRef<number>(0)

  const [state, setState] = useState<State>(() => {
    const {
      post: { grid },
    } = props
    const swipedSquares: SwappedSquare[] = (grid?.squares || []).map((s) => ({ ...s, swappedIndex: s.reverseIndex }))
    return {
      squares: swipedSquares,
      timesMoved: 0,
    }
  })

  const { squareToSwap, reverseSquareToSwap, hiddenSquareIndex, squares, coordinates } = state

  const getViewSize = (): Size => {
    const screenWidth = Math.round(Dimensions.get('window').width)
    const size: Size = {
      width: screenWidth > 500 ? 500 : screenWidth,
      height: screenWidth > 500 ? 500 : screenWidth,
    }

    return size
  }

  const onPressSquare = (square: SwappedSquare): void => {
    if (squareToSwap) {
      refAttempt.current++

      const squareToSwap_1: SwappedSquare = {
        ...squareToSwap,
        swappedIndex: square.swappedIndex,
      }

      const squareToSwap_2: SwappedSquare = {
        ...square,
        swappedIndex: squareToSwap.swappedIndex,
      }

      const newSquares = squares.map((s) => {
        if (squareToSwap_1.index === s.index) return squareToSwap_1
        if (squareToSwap_2.index === s.index) return squareToSwap_2
        return s
      })

      setState({ squareToSwap: undefined, squares: newSquares })

      const isSolved = checkIfSolved(newSquares)
      if (isSolved) {
        const { onSolved } = props
        onSolved && onSolved(refAttempt.current)
      }
    } else {
      setState((s) => ({ ...s, squareToSwap: square }))
    }
  }

  const checkIfSolved = (squares: SwappedSquare[]): boolean => {
    const solved = squares.reduce((acc, square) => {
      if (square.swappedIndex !== undefined && square.swappedIndex !== null && square.swappedIndex !== square.index) {
        return false
      }
      return acc
    }, true)

    return solved
  }

  const size = getViewSize()

  const imageUrl = localPost.image?.url

  return (
    <Container style={{ ...size }}>
      {isSolved && imageUrl && (
        <ImagePreview
          source={{
            uri: imageUrl,
          }}
        />
      )}
      {!isSolved && localPost.grid?.coordinates && localPost.grid?.squareImageUrl && (
        <GridPreview style={{ ...size }}>
          {squares.map((square) => {
            const width = size.width / grid!.x
            const height = size.height / grid!.y

            const coordinate = localPost!.grid!.coordinates![square.index]
            const uri = localPost!.grid!.squareImageUrl![`${coordinate.y}-${coordinate.x}`]

            if (square.swappedIndex === undefined || square.swappedIndex === null) {
              return (
                <SquarePreviewContainer
                  disabled
                  key={`${square.index}`}
                  style={{
                    top: coordinate.y * height,
                    left: coordinate.x * width,
                    width,
                    height,
                  }}
                >
                  <LockContainer>
                    <Lock animate={hiddenSquareIndex == square.index} />
                  </LockContainer>
                </SquarePreviewContainer>
              )
            }

            const coordinateReverse = localPost!.grid!.coordinates![square.swappedIndex]

            let top = coordinateReverse.y * height
            let left = coordinateReverse.x * width

            const selected = square.index == squareToSwap?.index
            if (selected && coordinates) {
              top = coordinates.y - height / 2
              left = coordinates.x - width / 2
            }

            const reverseSelected = square.index == reverseSquareToSwap?.index

            if (reverseSelected && squareToSwap?.swappedIndex != undefined) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const squareToSwapcoordinates = localPost!.grid!.coordinates![squareToSwap.swappedIndex]
              top = squareToSwapcoordinates.y * height
              left = squareToSwapcoordinates.x * width
            }

            return (
              <SquarePreviewContainer
                key={`${square.index}`}
                onPress={() => {
                  onPressSquare(square)
                }}
                selected={selected}
                style={{
                  top,
                  left,
                  width,
                  height,
                }}
              >
                <SquarePreview
                  selected={!coordinates && selected}
                  source={{
                    uri,
                  }}
                />
              </SquarePreviewContainer>
            )
          })}
        </GridPreview>
      )}
    </Container>
  )
}
