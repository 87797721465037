import React from 'react'
import { GestureResponderEvent } from 'react-native'

import { Loader } from '../Loader'

import { Container } from './Refresh.styled'

import { Empty } from './Empty'
import { Error } from './Error'

export type EmptyType = {
  Icon?: React.ComponentType<any>
  label?: string
}

interface Props {
  loading: 0 | 1 | -1
  onRefresh?: () => void
  children?: JSX.Element | null
  small?: boolean
  empty?: EmptyType
}

export const Refresh = (props: Props): JSX.Element => {
  const { loading, onRefresh, children, empty, small } = props

  const onTap = (e: GestureResponderEvent) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (onRefresh) onRefresh()
  }

  return children ? (
    children
  ) : (
    <Container onPress={loading !== 1 ? onTap : undefined} disabled={!onRefresh}>
      {loading === 1 ? (
        <Loader size={small ? 20 : 40} />
      ) : (
        <React.Fragment>
          {loading === -1 ? <Error small={small} /> : empty ? <Empty {...empty} small={small} /> : null}
        </React.Fragment>
      )}
    </Container>
  )
}
