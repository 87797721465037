import styled from 'styled-components/native'
import styledWeb from 'styled-components'

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-Bold;
  font-size: 15px;
`

export const A = styledWeb.a`
  text-decoration: none;
`

export const Icon = styled.Image`
  margin-right: 10px;
  width: 20px;
  height: 20px;
`
