import React from 'react'
import { useTranslation } from 'react-i18next'

import AppStoreEN from '../../../assets/images/store/app-store/en/app-icon.component'
import AppStoreFR from '../../../assets/images/store/app-store/fr/app-icon.component'

import PlayStoreEN from '../../../assets/images/store/play-store/en/app-icon.component'
import PlayStoreFR from '../../../assets/images/store/play-store/fr/app-icon.component'

import { Container, A } from './AppStore.styled'

export const AppStore = (): JSX.Element => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Container>
      {language == 'en' && (
        <>
          <A href="https://apps.apple.com/fr/app/shuffln/id1616852182?l=en" target="_blank">
            <AppStoreEN style={{ width: 120, height: 40 }} />
          </A>

          <A href="https://play.google.com/store/apps/details?id=co.lylou.shuffln&hl=en&gl=US" target="_blank">
            <PlayStoreEN style={{ width: 135, height: 40 }} />
          </A>
        </>
      )}
      {language == 'fr' && (
        <>
          <A href="https://apps.apple.com/fr/app/shuffln/id1616852182?l=fr" target="_blank">
            <AppStoreFR style={{ width: 126, height: 40 }} />
          </A>

          <A href="https://play.google.com/store/apps/details?id=co.lylou.shuffln&hl=fr&gl=FR" target="_blank">
            <PlayStoreFR style={{ width: 135, height: 52 }} />
          </A>
        </>
      )}
    </Container>
  )
}
