import { saveData, loadData, clearData } from './utils/storage'

export class AppStorage {
  private static instance: AppStorage

  private _authInfos?: AuthInfos

  get authInfos(): AuthInfos | undefined {
    return this._authInfos
  }

  private constructor(_authInfos?: AuthInfos) {
    this._authInfos = _authInfos
  }

  public static async getAsyncInstance(): Promise<AppStorage> {
    if (!AppStorage.instance) {
      const authInfos = await loadAuthInfos()

      AppStorage.instance = new AppStorage(authInfos)
    }

    return AppStorage.instance
  }

  public static getInstance(): AppStorage {
    if (AppStorage.instance) {
      return AppStorage.instance
    }

    throw new Error('Please use getAsyncInstance first')
  }

  public setAuthInfos(_authInfos: AuthInfos): void {
    this._authInfos = _authInfos

    saveAuthInfos(_authInfos)
  }

  public clearAuthInfos(): void {
    this._authInfos = undefined

    clearAuthInfos()
  }
}

export interface AuthInfos {
  accessToken: string
  refreshToken: string
}

const KEY_AUTH_INFOS = 'AUTH_INFOS'

async function saveAuthInfos(authInfos: AuthInfos): Promise<void> {
  saveData<AuthInfos>(KEY_AUTH_INFOS, authInfos)
}

async function loadAuthInfos(): Promise<AuthInfos | undefined> {
  return loadData<AuthInfos>(KEY_AUTH_INFOS)
}

async function clearAuthInfos(): Promise<void> {
  clearData(KEY_AUTH_INFOS)
}
