import { gql } from '@apollo/client'

import { PageInfoFragment, PostFragment, UserFragment } from '../fragment'

export const DISCOVER = gql`
  query discover($unsolved: Boolean) {
    last3: publicFeed(pagination: { first: 20 }, filters: { x: 3, unsolved: $unsolved }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }

    last4: publicFeed(pagination: { first: 20 }, filters: { x: 4, unsolved: $unsolved }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }

    last5: publicFeed(pagination: { first: 20 }, filters: { x: 5, unsolved: $unsolved }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${UserFragment}
  ${PostFragment}
  ${PageInfoFragment}
`
