import { useTranslation } from 'react-i18next'

import { Container, Label } from './Empty.styled'

interface Props {
  Icon?: React.ComponentType<any>
  label?: string
  small?: boolean
}

export const Empty = (props: Props): JSX.Element => {
  const { Icon, label, small } = props

  const { t } = useTranslation()

  return (
    <Container>
      {Icon ? <Icon style={{ fontSize: small ? 70 : 127 }} /> : null}
      <Label style={{ fontSize: small ? 12 : 16 }}>{label || t('components_Refresh_empty')}</Label>
    </Container>
  )
}
