import { FieldPolicy, Reference, StoreObject } from '@apollo/client'
import { cloneDeep } from 'lodash'

import { PageInfo } from '../types'

interface Paginated<T> {
  pageInfo: PageInfo
  edges: {
    node: T
  }[]
}

export function mergePaginated<
  Item extends Reference | StoreObject | undefined,
  PaginatedItem extends Paginated<Item>,
>(): FieldPolicy<PaginatedItem, PaginatedItem, PaginatedItem> {
  return {
    merge(existing, incoming, { readField, args }) {
      let newIncoming = cloneDeep(incoming)
      if (existing) {
        if (args?.pagination?.after) {
          const mergedItems = newIncoming.edges.reduce((acc, edge) => {
            const existingEdge = existing.edges.find((m) => readField('id', m.node) === readField('id', edge.node))
            if (!existingEdge) {
              return [...acc, edge]
            }
            return acc
          }, existing.edges)

          newIncoming = {
            ...newIncoming,
            edges: mergedItems,
          }
        }
      }

      return newIncoming
    },
  }
}
