import { Image } from 'react-native'

import { ImageSize, LocalPost, Post } from '../types'
import { getGridCoordinates, getSquareImageUrl } from '../utils/grid'

import { downloadImage } from './download-image'

async function getSizeOfImage(url: string): Promise<ImageSize | undefined> {
  return new Promise((resolve, reject) => {
    Image.getSize(
      url,
      (width: number, height: number) => resolve({ width, height }),
      (error) => {
        console.error(error)
        reject()
      },
    )
  })
}

export async function loadPostImage(_post: Post, localPost: LocalPost): Promise<LocalPost> {
  if (!process.env.API_REST_ENDPOINT) {
    throw new Error("'API_REST_ENDPOINT' has not been set")
  }

  const url = `${process.env.API_REST_ENDPOINT}/posts/shared/${localPost.sharedId}/image`

  const localUrl = await downloadImage(url)
  const imageSize = localUrl ? await getSizeOfImage(localUrl) : undefined
  if (localUrl && imageSize) {
    return {
      ...localPost,
      image: { url: localUrl, size: imageSize },
    }
  }

  return localPost
}

export async function loadPostGrid(post: Post, localPost: LocalPost): Promise<LocalPost> {
  const coordinates = post.grid ? getGridCoordinates(post.grid) : undefined
  const squareImageUrl =
    post.grid && coordinates && localPost.image
      ? await getSquareImageUrl(localPost.image.size, coordinates, post.grid, localPost.image.url)
      : undefined

  if (coordinates && squareImageUrl) {
    return {
      ...localPost,
      grid: { coordinates, squareImageUrl },
    }
  }

  return localPost
}
