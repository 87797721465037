import React, { useRef } from 'react'
import { LinkingOptions, NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'

import { Firebase } from '../../analytics/firebase'

import { MainStack, MainStackName } from '../MainStack'

import { RootStackParamList } from './RootStack.params'
import { ViewPostScreenName } from '../../screens/ViewPost'
import { ResolvePostScreenName } from '../../screens/ResolvePost'
import { SplashScreen, SplashScreenName } from '../../screens/Splash'
import { NotFoundScreen, NotFoundScreenName } from '../../screens/NotFound'

const Stack = createStackNavigator<RootStackParamList>()

export const RootStack = (): JSX.Element => {
  const navigationRef = useNavigationContainerRef()
  const routeNameRef = useRef<string>()

  const config: LinkingOptions<ReactNavigation.RootParamList>['config'] = {
    screens: {
      [MainStackName]: {
        screens: {
          [ViewPostScreenName]: {
            path: 'view/:sharedId',
            exact: true,
          },
          [ResolvePostScreenName]: {
            path: 'solve/:sharedId',
            exact: true,
          },
        },
      },
      [SplashScreenName]: '',
      [NotFoundScreenName]: '*',
    },
  }
  const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: ['http://localhost', 'https://staging.shufflin.app', 'https://shufflin.app'],
    config,
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      onReady={() => {
        routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name
        const currentRouteName = routeNameRef.current

        Firebase.getInstance().setCurrentScreen(currentRouteName)
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name

        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK

          Firebase.getInstance().setCurrentScreen(currentRouteName)
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName
      }}
    >
      <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.ModalFadeTransition }}>
        <Stack.Screen name={MainStackName} component={MainStack} />

        <Stack.Screen name={SplashScreenName} component={SplashScreen} options={() => ({ title: 'Splash' })} />

        <Stack.Screen name={NotFoundScreenName} component={NotFoundScreen} options={() => ({ title: 'Not found' })} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}
