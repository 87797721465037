import styled from 'styled-components/native'

export const Container = styled.View`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`

export const SectionContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
