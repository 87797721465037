import { Animated } from 'react-native'

import {
  APP_SET_SEARCH_QUERY,
  APP_SET_RANKING_TAB,
  AppActionTypes,
  APP_SET_HOME_TAB,
  APP_SET_POST_LIST_FILTER,
} from './actionTypes'

import { RankingSorting } from '../../types'

export interface AppState {
  search: {
    query: string
  }
  home: {
    tab: 'feed' | 'daily'
    animatedOffset: Animated.Value
  }
  ranking: {
    tab: RankingSorting
  }
  postList: {
    filter?: 'solved' | 'unsolved'
  }
}

export const defaultState: AppState = {
  search: {
    query: '',
  },
  home: {
    tab: 'daily',
    animatedOffset: new Animated.Value(0),
  },
  ranking: {
    tab: RankingSorting.Date,
  },
  postList: {
    filter: undefined,
  },
}

export const app = (state: AppState = defaultState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case APP_SET_SEARCH_QUERY:
      return {
        ...state,
        search: {
          query: action.query,
        },
      }
    case APP_SET_HOME_TAB:
      return {
        ...state,
        home: {
          ...state.home,
          tab: action.tab,
        },
      }
    case APP_SET_RANKING_TAB:
      return {
        ...state,
        ranking: {
          tab: action.tab,
        },
      }
    case APP_SET_POST_LIST_FILTER:
      return {
        ...state,
        postList: {
          filter: action.filter,
        },
      }
    default:
      return state
  }
}
