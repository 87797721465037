import { Dimensions } from 'react-native'
import styled from 'styled-components/native'

export const Container = styled.View`
  background-color: #fff;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const SectionContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const Icon = styled.Image`
  margin: 20px 0;
  width: 42px;
  height: 42px;
`

export const LabelContainer = styled.View``

export const Label404 = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-SemiBold;
  font-size: ${() => (Dimensions.get('window').width <= 480 ? 20 : 30)}px;
`

export const LabelNotFound = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily}-Regular;
  font-size: ${() => (Dimensions.get('window').width <= 480 ? 20 : 30)}px;
`
