import AsyncStorage from '@react-native-async-storage/async-storage'

export async function saveData<T>(key: string, data: T): Promise<void> {
  try {
    const serializedState = JSON.stringify(data)
    await AsyncStorage.setItem(key, serializedState)
  } catch (err) {
    console.error(err)
  }
}

export async function loadData<T>(key: string): Promise<T | undefined> {
  try {
    const serializedState = await AsyncStorage.getItem(key)
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export async function clearData(key: string): Promise<void> {
  try {
    await AsyncStorage.removeItem(key)
  } catch (err) {
    console.error(err)
  }
}
