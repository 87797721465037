import { gql } from '@apollo/client'

import { PageInfoFragment, PostFragment, SharedPostFragment } from '../fragment'

export const POST = gql`
  query post($postId: String!) {
    post(postId: $postId) {
      ...PostFragment
    }
  }
  ${PostFragment}
`

export const SHARED_POST = gql`
  query sharedPost($postId: String!) {
    sharedPost(postId: $postId) {
      ...SharedPostFragment
    }
  }
  ${SharedPostFragment}
`

export const POSTS = gql`
  query posts($userId: String!, $pagination: PaginationInput!) {
    posts(userId: $userId, pagination: $pagination) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`

export const POSTS_LIKED = gql`
  query postsLiked($userId: String!, $pagination: PaginationInput!) {
    postsLiked(userId: $userId, pagination: $pagination) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`

export const POSTS_SOLVED = gql`
  query postsSolved($userId: String!, $pagination: PaginationInput!) {
    postsSolved(userId: $userId, pagination: $pagination) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  ${PostFragment}
  ${PageInfoFragment}
`
