import { ThunkAction } from 'redux-thunk'

import { AppState } from '../store'

import { LocalPost, Post } from '../../types'

import { POST_PREVIEW_ADD_LOCAL_POST, PostActionTypes } from './actionTypes'
import { Queue } from '../../utils/queue'
import { loadPostImage, loadPostGrid } from '../../utils/post-preview'
import { getKeyFromCoordinate } from '../../utils/grid'

type Thunk<R> = ThunkAction<R, AppState, null, PostActionTypes>

const queueGrid = new Queue<void>()

export const addLocalPost =
  (post: Post, sharedId: string): Thunk<void> =>
  async (dispatch, state) => {
    const posts = state().postPreview.posts
    const localPost: LocalPost = posts.find((p) => p.id === post.id) || {
      id: post.id,
      sharedId,
      loadingImage: 0,
      loadingGrid: 0,
    }

    const isLoadingImage = localPost.loadingImage == 1
    const isLoadingGrid = localPost.loadingGrid == 1
    let isImagePathValid = true
    let isSquareImagePathValid = true

    try {
      isImagePathValid = !!localPost.image?.url

      if (localPost.grid) {
        for (const coordinate of localPost.grid.coordinates) {
          const squarePath = localPost.grid.squareImageUrl[getKeyFromCoordinate(coordinate)]
          if (!squarePath) {
            isSquareImagePathValid = false
          }
        }
      } else {
        isImagePathValid = false
      }
    } catch (error) {
      isImagePathValid = false
      isSquareImagePathValid = false
    }

    const isLoadingRequired = !isLoadingImage && !isLoadingGrid && (!isImagePathValid || !isSquareImagePathValid)

    if (isLoadingRequired) {
      dispatch({
        type: POST_PREVIEW_ADD_LOCAL_POST,
        post: {
          ...localPost,
          image: undefined,
          grid: undefined,
          loadingImage: 1,
        },
      })

      try {
        const loadedPostWithImage = await loadPostImage(post, localPost)

        dispatch({
          type: POST_PREVIEW_ADD_LOCAL_POST,
          post: {
            ...loadedPostWithImage,
            loadingImage: 0,
          },
        })

        if (post.grid) {
          queueGrid.push(async () => {
            dispatch({
              type: POST_PREVIEW_ADD_LOCAL_POST,
              post: {
                ...loadedPostWithImage,
                loadingImage: 0,
                loadingGrid: 1,
              },
            })

            const loadedPostWithGrid = await loadPostGrid(post, loadedPostWithImage)

            dispatch({
              type: POST_PREVIEW_ADD_LOCAL_POST,
              post: {
                ...loadedPostWithGrid,
                loadingImage: 0,
                loadingGrid: 0,
              },
            })
          })
        }
      } catch (error) {
        dispatch({
          type: POST_PREVIEW_ADD_LOCAL_POST,
          post: {
            ...localPost,
            image: undefined,
            grid: undefined,
            loadingImage: -1,
          },
        })
      }
    }
  }
