import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 40 53" width={40} height={53} fill="none" {...props}>
    <Path
      // eslint-disable-next-line max-len
      d="M32 17.5v.5h3c2.474 0 4.5 2.026 4.5 4.5v25c0 2.474-2.026 4.5-4.5 4.5H5a4.515 4.515 0 0 1-4.5-4.5v-25C.5 20.026 2.526 18 5 18h3v-5.5c0-6.624 5.376-12 12-12s12 5.376 12 12v5Zm-4.5.5h.5v-5.5c0-4.426-3.574-8-8-8a7.99 7.99 0 0 0-8 8V18h15.5ZM6 48h28a1.5 1.5 0 0 0 1.5-1.5v-23A1.5 1.5 0 0 0 34 22H6a1.5 1.5 0 0 0-1.5 1.5v23A1.5 1.5 0 0 0 6 48Zm18.5-13c0 2.474-2.026 4.5-4.5 4.5a4.515 4.515 0 0 1-4.5-4.5c0-2.474 2.026-4.5 4.5-4.5s4.5 2.026 4.5 4.5Z"
      fill="#000"
      stroke="#000"
    />
  </Svg>
)

export default SvgComponent
