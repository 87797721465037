import { AppStorage } from '../App.storage'

export interface RestParams {
  url: string
  method?: string
  headers?: { [key: string]: any }
  body?: any
}

export const rest = async (params: RestParams): Promise<Response> => {
  const { url, method, headers, body } = params

  const req = {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body,
  }

  const res = await fetch(url, req)
  return res
}

export const restWithToken = async (params: RestParams): Promise<Response> => {
  const authInfos = AppStorage.getInstance().authInfos
  if (!authInfos) {
    throw Error('You are not authenticated')
  }

  return await rest({
    ...params,
    headers: {
      ...params.headers,
      Authorization: `Bearer ${authInfos.accessToken}`,
    },
  })
}

export const formatUrlWithParams = (url: string, params: { [key: string]: any }): string => {
  const formattedUrl = Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, params[key]), url)
  return formattedUrl
}

export const formatUrlWithQueryParams = (url: string, params: { [key: string]: any }): string => {
  const formattedUrl = Object.keys(params).reduce(
    (acc, key, index) => `${acc}${index === 0 ? '?' : '&'}${[key]}=${params[key]}`,
    url,
  )
  return formattedUrl
}
