import { ProfileImage } from '../../types'

export const PROFILE_IMAGE_DOWNLOAD = '@@profile_image/DOWNLOAD'

export interface DownloadProfileImage {
  type: typeof PROFILE_IMAGE_DOWNLOAD
  image: ProfileImage
}

export type ProfileImageActionTypes = DownloadProfileImage
